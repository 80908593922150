.NavButtons{
  @include flex(center);
  flex-direction: column;
  gap: 0.5em;

  .Footer &{
    align-items: flex-start;
  }

  .Nav &{
    @include responsive($menuBreakpoint){
      flex-direction: row;
      gap: 0.5em;
    }
  }

  .NavDropdown &{
    @include responsive($menuBreakpoint){
      display: none;
    }
  }

  .Btn{
    margin-top: 0;

    &.--orange{
      .Nav &{
        body.--show-menu &{
          border-color: transparent;
          background-color: $orange-crepuscule;
        }
      }
    } 

    &.--green{
      .Nav &,
      .NavDropdown &{
        .Header.--home &,
        body.--show-menu &{
          border-color: transparent;
          background-color: $vert-prairie-l1;

          > span{
            color: $ardoise;
          }

          > .Icon.--donation svg > *{
            stroke: $ardoise;
          }
        }
      }
    }

    .Nav &{
      background-color: transparent;
      border: 1.5px solid rgba($white, 0.3);
    }

    body:not(.--show-menu) .Header.--dark:not(.--scroll) .Nav &{
      background-color: transparent;
      border: 1px solid rgba(#1A1A1A, 0.1);

      > span{
        color: $ardoise;
      }

      > .Icon.--ticket svg > *,
      > .Icon.--donation svg > *{
        stroke: $ardoise;
      }
    }

    &.--orange,
    &.--green{
      > span{
        color: $white;
      }
    }

    > .Icon.--ticket svg > *,
    > .Icon.--donation svg > *{
      stroke: $white;
    }
  }
}

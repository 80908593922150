.Select{
  @include responsive($tabletSmall){
    grid-column: span 2;
  }

  .wrapper{
    position: relative;

    select{
      // padding: 1em 3.5em 1em 1em;
      padding: 1.375em 3.5em 1.375em 1.625em;
      width: 100%;
      color: $black;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border-radius: 20em;
      border: 1.5px solid $bleu-marine-l3;
    }

    > .Icon.--chevron{
      position: absolute;
      top: 50%; right: 2em;
      transform: translate(0, -50%);
      pointer-events: none;
    }
  }
}

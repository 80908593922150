.ContentSingle{
  .wswyg--content{
    @include responsive($tabletSmall){
      max-width: get-vw(600);
      margin-left: auto;
    }

    @include responsive($deskXXL){
      // max-width: 600px;
      max-width: 55%;
    }

    h1, h2, h3, h4, h5, h6{
      font-family: $main-font;
      font-style: normal;
      font-weight: 500;
    }

    img{
      width: 100%;
      border-radius: 10px;
    }
  }
}

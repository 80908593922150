.woocommerce{
  .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) #respond input#submit.alt:hover, 
  .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) a.button.alt:hover, 
  .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) button.button.alt:hover, 
  .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) input.button.alt:hover, 
  :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce #respond input#submit.alt:hover, 
  :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce a.button.alt:hover, 
  :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce button.button.alt:hover, 
  :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce input.button.alt:hover,
  .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) #respond input#submit, 
  .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) a.button, 
  .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) button.button, 
  .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) input.button, 
  :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce #respond input#submit, 
  :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce a.button, 
  :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce button.button, 
  :where(body:not(.woocommerce-block-theme-has-button-styles)) .woocommerce input.button,
  .woocommerce-MyAccount-downloads-file,
  .order-again .button{
    border-radius: 20em !important;
    background-color: $orange-crepuscule !important;
    color: $white !important;
  }

  .woocommerce-thankyou-order-received{
    color: $vert-prairie;
  }
  
  .woocommerce-info{
    margin-top: 1em;
    border-top-color: $black;

    &::before{
      color: $black;
    }
  }

  .woocommerce-form-login{
    input[type="text"],
    input[type="password"]{
      padding: 1.375em 1.625em;
      width: 100%;
      border: 1.5px solid $bleu-marine-l3;
      border-radius: 20em;

      &[type="search"]::-webkit-search-decoration,
      &[type="search"]::-webkit-search-cancel-button,
      &[type="search"]::-webkit-search-results-button,
      &[type="search"]::-webkit-search-results-decoration {
        display: none;
      }

      &::placeholder{
        color: $ardoise;
        opacity: 0.5;
      }
    }

    input[type="checkbox"]{
      @include flex(center, center);
      height: get-vw(16);
      width: get-vw(16);
      border: 1.5px solid rgba($ardoise, 0.35);
      border-radius: 3px; 
      transition: background-color 450ms $ease;

      @include responsive($tabletSmall, max){
        min-height: 16px;
        min-width: 16px;
      }

      @include responsive($desk){
        max-width: 16px;
        max-height: 16px;
      }

      &:checked{
        background-color: $ardoise;
      }
    }

    .woocommerce-form-login__rememberme{
      display: inline-flex;
      align-items: center;
      gap: 1em;
    }
  }

  .woocommerce-order > ul.order_details.woocommerce-thankyou-order-details{
    margin-top: 2em;
  }

  .woocommerce-order-details,
  .woocommerce-customer-details,
  .woocommerce-order-downloads{
    padding: 2em 0;

    h2{
      @include font-limited(50);
      margin-bottom: 0.5em;
    }
  }

  // .woocommerce-order-details,
  // .woocommerce-order-downloads{
  //   padding-top: 3.5em;
  // }
}

.woocommerce-notices-wrapper{
  &:not(.woocommerce-error){
    > .woocommerce-message{
      background-color: $vert-prairie-l2;
    }
  }

  &,
  > .woocommerce-message{
    border-radius: 20px;
    overflow: hidden;
  }

  > .woocommerce-message{
    border-top: 0 !important;

    a{ text-decoration: underline !important; }
  }
}

.checkout-button.button.alt.wc-forward{
  background: $orange-crepuscule !important;

  &:hover{
    background: $orange-crepuscule !important;
  }
}

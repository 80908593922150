.Pagination{
  margin-top: 5em;

  &.--orange{
    ul {
      li {
        > *.--active{
          background-color: $orange-crepuscule;
        }
      }
    }
  }

  ul{
    @include flex(center, center);
    flex-wrap: wrap;
    gap: 0.45em;

    li{
      > *{
        @include flex(center, center);
        font: 600 14px $main-font;
        @include font-limited(14);
        background-color: $white;
        color: $ardoise;
        border-radius: 50%;
        transition: background-color 500ms $ease,
                    color 500ms $ease;

        width: 35px;
        height: 35px;

        @include responsiveMinMax($tabletSmall, $deskXXL){
          width: get-vw(35);
          height: get-vw(35);
        }

        &.--active{
          background-color: $bleu-marine;
          color: $white;
        }
      }

      > span{
        pointer-events: none;
      }
    }
  }
}
.NavCart{
  @include flex(center, center);
  pointer-events: all;
  background-color: $white;
  border-radius: 50%;
  padding: 0.25em 0.5em;

  height: 38px;
  width: 38px;

  body:not(.--show-menu) .Header.--dark &{
    background-color: $pierre-blanche;
  }

  body:not(.--animating) &{
    transition: transform 500ms $ease,
                background-color 500ms $ease;
  }

  body.--show-menu &{
    background-color: $ardoise-d2;

    .Icon.--basket svg > *{
      stroke: $white;

      &:nth-child(1),
      &:nth-child(2){
        fill: $white;
      }
    }
  }

  @include noTouchHover() {
    transform: scale(0.95);

    .Icon.--basket {
      transform: rotate(4deg);
    }
  }
  
  @include responsive($tabletSmall){
    height: get-vw(48);
    width: get-vw(48);
  }

  @include responsive($deskXXL){
    height: 48px;
    width: 48px;
  }

  .Icon.--basket {
    transition: transform 500ms $ease;

    svg {
      @include responsive($tabletSmall, max){
        min-width: 15px;
      }

      > *{
        transition: fill 500ms $ease,
                    stroke 500ms $ease;
      }
    }
  }
}
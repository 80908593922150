.PageHeaderSingle {
  position: relative;
  color: $white;
  padding-top: 10em;
  padding-bottom: 3.5em;
  border-radius: 0 0 30px 30px;
  background-color: $bleu-marine;

  &:after {
    @include content();
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-repeat: repeat;
    pointer-events: none;

    // No gradient on mobile 
    @include responsive($tabletSmall, max) {
      opacity: 0.02;
      background: url('../imgs/rosace.svg');
    }

    @include responsive($tabletSmall) {
      max-width: 50%;
      opacity: 0.15;
      background-position: right bottom;
      background: radial-gradient(65% 100% at 100% 100%, rgba($bleu-marine, 0.5) 0%, $bleu-marine 100%), 
                  url("../imgs/rosace.svg");
    }
  }

  // Special styles for Resource single
  .Resource &{
    background-color: $bleu-marine-l1;

    &:after{
      @include responsive($tabletSmall) {
        background: radial-gradient(65% 100% at 100% 100%, rgba($bleu-marine-l1, 0.5) 0%, rgba($bleu-marine-l1, 1) 100%), 
                    url("../imgs/rosace.svg");
      }
    }
  }

  .container{
    z-index: 1;
  }

  .container > .push-right{
    width: 100%;
    
    @include responsive($tabletSmall){
      max-width: get-vw(600);
      padding-right: space(1);
      margin-left: auto;
    }

    @include responsive($deskXXL){
      // max-width: 600px;
      max-width: 55%;
    }

    .Btn{
      @include font-limited(14);
      font-size: 14px;
      padding: 0.93em 1.4em;
      flex-direction: row-reverse;
      margin-top: 0;

      .Resource &{
        background-color: $bleu-marine;
      }

      .Icon.--arrow{
        transform: scaleX(-1);
      }
    }

    h1{
      margin: 0.85em 0;
    }

    .post-infos{
      @include flex(center);
      flex-wrap: wrap;
      gap: 1em;
    }
  }
}

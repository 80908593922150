.Copyright {
   @include flex(center, center);
   gap: 0.4em;

   .wkn {
    body:not(.--animating) &{
      transition: transform 500ms $ease;
    }

    svg > *{
      fill: $white;
    }
   }

   @include noTouchHover() {
    .wkn{
      transform: scale(0.85) rotate(2deg);
    }    
   }
}

.TimelineEvent{
  @include flex(flex-start, center);
  
  @include responsive($tabletSmall, max){
    flex-direction: column;
    gap: 2em;
  }

  &[data-type="image_left"]{
    @include responsive($tabletSmall){
      flex-direction: row-reverse;
    }

    .wrapper{
      @include responsive($tabletSmall){
        margin-left: auto;
        margin-right: 5em;
      }
    }
  }

  &[data-type="image_right"]{
    .wrapper{
      @include responsive($tabletSmall){
        margin-right: auto;
        margin-left: 5em;
      }
    }
  }

  &.--no-visual{
    @include responsive($tabletSmall){
      margin-left: auto;
      margin-right: auto;
    }

    .wrapper{
      @include responsive($tabletSmall){
        @include flex(flex-start, center);
        gap: 7.5em;
      }
    }
  }

  .wrapper{
    time{
      font: 600 18px $fraunces;
      @include font-limited(20);
      color: $white;
      margin-bottom: 1em;
      display: block;
    }

    h3{
      font: 700 30px $main-font;
      @include font-limited(60);
      text-transform: uppercase;
      line-height: 0.8;
      color: $miel-dore;
      margin-bottom: 0.7em;

      @include responsive($tabletSmall){
        max-width: get-vw(650);
      }

      @include responsive($deskXXL){
        max-width: 650px;
      }

      i{
        font: italic 700 0.83em $fraunces;
        text-transform: initial;
      }
    }

    .wswyg--content{
      color: $white;
      
      @include responsive($tabletSmall){
        max-width: get-vw(470);
      }

      @include responsive($deskXXL){
        max-width: 470px;
      }
    }
  }

  .visual__container{
    @include responsive($tabletSmall){
      max-width: get-vw(535);
    }

    @include responsive($deskXXL){
      // max-width: 535px;
      max-width: 40%;
    }
  }
}

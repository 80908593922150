.ProductContent{
  padding-top: 0;

  .container{
    // @include flex(flex-start, space-between);
    // gap: 2em;
    // flex-wrap: wrap;

    display: grid;
    grid-gap: 1em;
    
    @include responsive($tabletSmall){
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0.5em;
    }

    p:empty{
      display: none;
    }

    > *{
      &:not(h1),
      &:not(h2),
      &:not(h3),
      &:not(h4),
      &:not(h5),
      &:not(h6){
        @include responsive($tabletSmall){
          grid-column: 2/3;
        }
      }
    }

    h2, h3, h4, h5, h6{
      @include font-limited(50);
      
      @include responsive($tabletSmall){
        position: absolute;
        top: 0; left: 0;
        grid-column: 1/2;
      }
    }

    p:has(img) {
      @include responsive($tabletSmall){
        margin-top: 2em;
        grid-column: 1/3;
      }
    }

    img{
      border-radius: 20px;
      width: 100%;
    }

    a{
      font-weight: 700;
      text-decoration: underline;
    }
  }
}

.Checkbox{
  @include flex(center);
  gap: 0.75em;
  padding: 0.75em 1.5em 0.75em 1em;
  position: relative;
  transition: transform 450ms $ease;

  > input{
    all: unset;
    @include cover();
    cursor: pointer;
    border-radius: 10px;

    &:checked{
      & ~ .check{
        background: $ardoise;
        border-color: $ardoise;

        .Icon.--tick{
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  > input,
  > .check{
    transition: border-color 500ms $ease,
                background 500ms $ease;
  }

  > .check,
  > span{
    position: relative;
    z-index: 1;
    pointer-events: none;
  }

  > .check{
    @include flex(center, center);
    height: get-vw(16);
    width: get-vw(16);
    border: 1.5px solid rgba($ardoise, 0.35);
    border-radius: 3px; 

    @include responsive($tabletSmall, max){
      min-height: 16px;
      min-width: 16px;
    }

    @include responsive($desk){
      max-width: 16px;
      max-height: 16px;
    }

    .Icon.--tick {
      opacity: 0;
      transform: scale(0.2);
      transition: transform 250ms $ease;

      svg > *{
        stroke: $white;
      }
    }
  }
        
  > span{
    font: 600 16px $main-font;
    @include font-limited(16);
    color: $ardoise;
    transition: color 500ms $ease;
  }
}
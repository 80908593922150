.Tales{
  .grid{
    display: grid; 
    grid-gap: 1em;

    @include responsive($tabletSmall, max){
      grid-template-columns: repeat(7, 1fr);
    }
    
    @include responsive($tabletSmall){
      grid-template-columns: repeat(12, 1fr);
    }
  }
}

.SpecificRequestCard{
  @include flex(center, center);
  flex-direction: column;
  gap: 0.5em;
  text-align: center;
  color: $ardoise;

  > h3{
    font: normal 600 20px $fraunces;
    @include font-limited(26);
    line-height: 1.2;
    text-transform: initial;
  }
}

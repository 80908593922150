.FooterNavBlock{
  color: $white;
  
  > span{
    font-family: $fraunces;
    margin-bottom: 0.8em;
    filter: opacity(0.5);
  }

  ul:nth-child(3) {
    margin-top: 0.5em;

    .Btn{
      padding: 0.75em 1.25em;
      margin-top: 0.8em;
    }
  }

  .Btn.--green{
    > span{
      color: $black;
    }

    > .Icon.--donation svg > *{
      stroke: $black;
    }
  }
}

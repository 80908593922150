.ContentWithIllustration{
  position: relative;
  overflow: clip;
  background-color: $white;
  color: $ardoise;

  &.--blue{
    background-color: $bleu-marine-l2;
  }

  &.--ardoise{
    background-color: $ardoise-d1;
    color: $white;
  }

  .container{
    @include flex(flex-start);
    z-index: 1;

    @include responsive($tabletSmall, max){
      flex-direction: column;
      gap: 2em;
    }
  }

  .container > h2{
    @include responsive($tabletSmall){
      position: sticky;
      top: 1em;
      margin-left: 1.5em;
      max-width: get-vw(465);
    }

    @include responsive($deskXXL){
      max-width: 465px;
    }
  }

  .container > .wswyg--content{
    line-height: 1.7;

    @include responsive($tabletSmall){
      margin-left: auto;
      max-width: get-vw(410);
      margin-right: 5em;
    }

    @include responsive($deskXXL){
      // max-width: 410px;
      max-width: 40%;
    }
  }
  
  // Illustration
  > .visual__container{
    position: absolute;
    z-index: 0;
    pointer-events: none;
    
    &.illustration_1{
      height: 100%;
      bottom: 0; 
      opacity: 0.2;

      @include responsive($tabletSmall, max){
        left: 0;
        width: 200%;
      }

      @include responsive($tabletSmall){
        left: -30%;
        width: 140%;
      }
    }

    &.illustration_2{      
      @include responsive($tabletSmall, max){
        bottom: 0; left: 0;
        opacity: 0.1;
      }
        
      @include responsive($tabletSmall){
        max-width: get-vw(934);
        bottom: -15%; left: -21.5%;
        opacity: 0.05;
      }

      @include responsive($deskXXL){
        max-width: 934px;
      }
    }

    &.illustration_3{
      top: 0; left: 5%;
      opacity: 0.3;

      @include responsive($tabletSmall, max){
        max-width: 100%;
      }
      
      @include responsive($tabletSmall){
        max-width: get-vw(620);
      }

      @include responsive($deskXXL){
        max-width: 620px;
      }
    }
  }
}

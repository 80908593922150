.PageHeaderWithNav{
  position: relative;
  color: $white;
  padding-bottom: 3.5em;

  .Monastery &{
    background-color: $ardoise-d2;
  }

  .container{
    @include flex(flex-start);
    flex-direction: column;
    gap: 2em;
    z-index: 1;

    @include responsive($tabletSmall, max){
      gap: 2em;
    }
    
    nav{
      background-color: rgba($white, 0.1);
      backdrop-filter: blur(100px);
      padding: 1.7em;
      // padding: 1em;

      @include responsive($tabletSmall, max){
        max-width: 460px;
      }

      @include responsive($tabletSmall){
        margin-left: auto;
      }

      ul{
        @include flex(center);
        flex-wrap: wrap;
        gap: 1em;

        @include responsive($mobile, max){
          align-items: flex-start;
          flex-direction: column;
        }

        li{
          a{
            font: 700 18px $main-font;
            @include font-limited(18);
            opacity: 0.4;

            &.--active{
              opacity: 1;
            }
          }
        }
      }
    }    
  }

  .visual__container {
    z-index: 0;
    pointer-events: none;
    
    &, 
    &:after{
      @include cover();
    }

    &:after{
      @include content();
      background-color: rgba($black, 0.4);
    }
  }
}

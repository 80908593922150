.Heritage {
  position: relative;
  overflow: clip;
  
  .container{
    z-index: 1;

    > *{
      position: relative;
    }
  }

  .container > .head{
    @include flex(center, center);
    flex-direction: column;
    color: $ardoise;
    margin: 0 auto;
    z-index: 0;

    @include responsiveMinMax($tabletSmall, $deskXXL){
      max-width: get-vw(760);
    }

    h2{
      strong{
        display: block;
        text-align: right;
      }
    }

    .wswyg--content{
      text-align: center;
      margin-top: 2em;
      max-width: 40em;
    }
  }

  .container > .grid{
    @include flex(flex-start);
    flex-wrap: wrap;
    gap: 2em;
    margin-top: 2em;
    z-index: 1;

    @include responsive($tabletSmall){
      padding: 0 2.5em;
    }

    > * {
      &:not(:first-child) {
        @include responsive($tabletSmall){
          margin-top: 3.5em;
        }
      }

      &:nth-child(3n+2){
        @include responsive($tabletSmall){
          margin-left: auto;
        }
      }

      &:nth-child(3n+3){
        @include responsive($tabletSmall){
          margin-bottom: 4em;
          margin-left: get-vw(230);
        }
      }
    }
  }

  // Illustration
  > .sticky-illustration{
    @include cover();
    z-index: 0;

    .visual__container{
      @include imgRatio(1440, 1278);
      position: sticky;
      top: 0;
      max-height: 100dvh;
    }
  }
}

.Cart{
  .page-cart-content{
    padding-top: 2.5em;
    padding-bottom: 5em;
  }

  .woocommerce{
    padding-top: 2.5em;

    /**
    * Empty cart
    */
    .cart-empty.woocommerce-info{
      border: none;
      background-color: $pierre-blanche;
      border-radius: 20px;

      &:before{
        color: $ardoise;
      }
    }

    .return-to-shop > a,
    .checkout-button,
    #respond input#submit.alt, 
    a.button.alt, 
    button.button.alt, 
    input.button.alt{
      cursor: pointer;
      font: 600 14px $main-font;
      @include font-limited(18);

      display: inline-flex;
      align-items: center;
      gap: 0.5em;
      background: #2C296C;
      color: $white;
      border-radius: 20em;
      padding: 1.14em 2em;
      margin-top: 1.5em;

      background-color: $orange-crepuscule;
    
      span{
        color: $white;
      }
    }

    /**
    * Products cart
    */

    .product-quantity{
      .quantity{
        @include flex(center);

        @include responsive($tabletSmall, max){
          justify-content: flex-end;
        }

        button{
          display: none;
        }

        input{
          font: 400 16px $main-font;
          @include font-limited(16);
          color: $ardoise;
          width: 100%;
          border-radius: 20em;
          background-color: $grey-l;
          text-align: center;
          padding: 0.5em;
          max-width: 5em;
          
          @include responsive($tabletSmall){
            padding: 1.375em 1.625em;
            max-width: get-vw(100);
          }

          @include responsive($deskXXL){
            max-width: 100px;
          }
        }
      }
    }

    .actions{
      button[type="submit"].button{
        @include responsive($tabletSmall, max){
          text-align: center;
        }
      }

      .coupon{
        @include flex();
  
        input.input-text#coupon_code{
          text-align: left;
          width: auto;
          background-color: $grey-l;
          padding: 0 1em;
        }
      }
    }


    .cart_totals{
      margin-top: 2em;

      h2{
        @include font-limited(30);
        margin-bottom: 0.5em;
      }
    }
  }
}

.Illustration{
  position: relative;

  svg {
    @include cover();
  }

  &.--chapel{
    @include imgRatio(648, 891);
  }
}

.illustration_1{
  @include imgRatio(2526, 1215);
}

.illustration_2{
  @include imgRatio(934, 1285);
}

.illustration_3{
  @include imgRatio(620, 1080);
}
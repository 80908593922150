.PageHeaderHome{
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  background-color: $pierre-blanche;

  > .inner{
    position: relative;
    border-radius: 0 0 30px 30px;
    overflow: hidden;

    .container{
      padding-top: 20em;
      padding-bottom: 2.5em;
      background-color: $ardoise-d1;
      color: $pierre-blanche;
      
      @include responsive($tabletSmall){
        padding-bottom: 1.5em;
      }
    
      .wrapper{
        position: relative;
        z-index: 1;
      }
    }

    .video{
      overflow: hidden;
      z-index: 0;

      &, video{
        position: absolute;
        top: 0; left: 0;
        min-height: 100%;
        min-width: 100%;
      }

      &:before,
      &:after{
        @include content();
        @include cover();
        z-index: 1;
      }

      &:before{
        background-color: rgba(black, 0.1);
      }

      &:after{
        background: linear-gradient(180deg, rgba(black, 0) 0%, rgba($black, 0.6) 100%);
      }
    }
  }  
}

.PageHeaderWithRosace {
  position: relative;
  z-index: 1;
  color: $white;
  padding-bottom: 2.5em;
  padding-top: 15em;
  border-radius: 0 0 30px 30px;
  overflow: hidden;
  
  // To fix the confrontation with PageHeaderWithNav
  &:not(.--with-visual){
    background-color: $bleu-marine;
    
    &:after {
      @include content();
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background-repeat: repeat;
      pointer-events: none;

      // No gradient on mobile 
      @include responsive($tabletSmall, max) {
        opacity: 0.02;
        background: url('../imgs/rosace.svg');
      }

      @include responsive($tabletSmall) {
        max-width: 50%;
        opacity: 0.15;
        background-position: right bottom;
        background: radial-gradient(80% 65% at 70% 70%, rgba($bleu-marine, 0.5) 0%, $bleu-marine 100%),
                    url('../imgs/rosace.svg');
      }
    }
  }

  // Special themes
  @each $slug, $background, $color in $page-header-with-rosace-themes {
    &.--#{$slug}{
      background-color: $background;

      &:after {
        @include responsive($tabletSmall) {
          background: radial-gradient(80% 65% at 70% 70%, rgba($background, 0.5) 0%, $background 100%),
                      url('../imgs/rosace.svg');
        }
      }

      .titles{
        h1{
          color: $color;
        }
      }
    }
  }

  // Special styles for Resources archive
  // .Resources & {
  //   background-color: $bleu-marine-l1;

  //   &:after {
  //     @include responsive($tabletSmall) {
  //       background: radial-gradient(80% 65% at 70% 70%, rgba($bleu-marine-l1, 0.5) 0%, $bleu-marine-l1 100%),
  //                   url('../imgs/rosace.svg');
  //     }
  //   }
  // }

  // Special styles for Vocation page
  // .Vocation & {
  //   background-color: $vert-prairie-l1;

  //   &:after {
  //     @include responsive($tabletSmall) {
  //       background: radial-gradient(80% 65% at 70% 70%, rgba($vert-prairie-l1, 0.5) 0%, $vert-prairie-l1 100%),
  //                   url('../imgs/rosace.svg');
  //     }
  //   }
  // }

  // Special styles for Monastery page
  // .Monastery &{
  //   background-color: $ardoise-d2;

  //   &:after {
  //     @include responsive($tabletSmall) {
  //       background: radial-gradient(80% 65% at 70% 70%, rgba($ardoise-d2, 0.5) 0%, $ardoise-d2 100%),
  //                   url('../imgs/rosace.svg');
  //     }
  //   }
  // }

  // Special styles for Contact page
  // .Contact &{
  //   background-color: $miel-dore;

  //   &:after {
  //     @include responsive($tabletSmall) {
  //       background: radial-gradient(80% 65% at 70% 70%, rgba($miel-dore, 0.5) 0%, $miel-dore 100%),
  //                   url('../imgs/rosace.svg');
  //     }
  //   }

  //   .titles{
  //     h1{
  //       color: $ardoise-d1;
  //     }
  //   }
  // }

  // Special styles for Products archive
  // .Products &{
  //   background-color: $orange-crepuscule;

  //   &:after {
  //     @include responsive($tabletSmall) {
  //       background: radial-gradient(80% 65% at 70% 70%, rgba($orange-crepuscule, 0.5) 0%, $orange-crepuscule 100%),
  //                   url('../imgs/rosace.svg');
  //     }
  //   }
  // }

  // Special styles for Cart page
  // .Cart &{
  //   background-color: $ardoise-d1;

  //   &:after {
  //     @include responsive($tabletSmall) {
  //       background: radial-gradient(80% 65% at 70% 70%, rgba($ardoise-d1, 0.5) 0%, $ardoise-d1 100%),
  //                   url('../imgs/rosace.svg');
  //     }
  //   }
  // }

  .titles {
    @include responsive($tabletSmall) {
      max-width: 50%;
    }
  }
}

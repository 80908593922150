.Page {
  /**
  * Woocommerce
  */

  .wc-block-components-form .wc-block-components-text-input input[type='email'],
  .wc-block-components-form .wc-block-components-text-input input[type='number'],
  .wc-block-components-form .wc-block-components-text-input input[type='tel'],
  .wc-block-components-form .wc-block-components-text-input input[type='text'],
  .wc-block-components-form .wc-block-components-text-input input[type='url'],
  .wc-block-components-text-input input[type='email'],
  .wc-block-components-text-input input[type='number'],
  .wc-block-components-text-input input[type='tel'],
  .wc-block-components-text-input input[type='text'],
  .wc-block-components-text-input input[type='url'] {
    border-color: #d0d0d0;
  }

  .wc-block-components-button:not(.is-link){
    background-color: $orange-crepuscule;
    color: $white;
    border-radius: 20em;
    font-weight: 700;
  }
}

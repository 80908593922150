.HeritageCard{
  @include flex(flex-start);
  padding: 1.5em;
  gap: 2em;
  box-shadow: 0px 64px 74px rgba($black, 0.15);
  width: 100%;
  max-width: 650px;

  @include responsive($tabletSmall, max){
    flex-direction: column;
  }
  
  @include responsiveMinMax($tabletSmall, $deskXXL){
    max-width: get-vw(650);
  }
  
  @include responsive($tabletSmall){
    align-items: center;
    padding: 1em;
  }

  @each $slug, $background, $color in $heritage-themes {
    &[data-color="#{$slug}"]{
      background-color: $background;
      color: $color;
    }
  }

  > .visual__container{
    @include imgRatio(330, 240);
    
    @include responsive($tabletSmall){
      @include imgRatio(220, 310);
      max-width: get-vw(220);
    }

    @include responsive($deskXXL){
      max-width: 220px;
    }
  }

  > .wrapper{
    flex: 1;

    h3{
      font-size: 22px;
      @include font-limited(30);
      font-weight: 700;
      line-height: 0.9;
      margin-bottom: 0.7em;
    }

    .wswyg--content{
      font: 500 18px $main-font;
      @include font-limited(20);
      line-height: 1.4;
    }
  }
}


.CircleArrows{
  @include flex(center, center);
  position: relative;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  border: 1.5px solid $white;
  overflow: hidden;

  @include responsiveMinMax($tabletSmall, $deskXXL){
    height: get-vw(24);
    width: get-vw(24);
  }

  @media (pointer: fine) {
    .--btn-fake:hover &,
    &:hover{
      .Icon.--arrow{
        &:nth-child(1){
          transform: translate(150%, 0) scale(0);
        }

        &:nth-child(2){
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }

  .--big--circle-arrows &{
    width: 40px;
    height: 40px;

    @include responsiveMinMax($tabletSmall, $deskXXL){
      width: get-vw(40);
      height: get-vw(40);
    }

    .Icon.--arrow{
      @include svg-limited(22);
    }
  }

  .--dark-circle-arrows &{
    border-color: $black;

    .Icon.--arrow svg > *{
      stroke: $black;
    }
  }

  .Icon.--arrow{
    transition: transform 500ms $ease;

    &:nth-child(1){}

    &:nth-child(2){
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-150%, -50%) scale(0);
    }
  }
}

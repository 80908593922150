.ContentPicture{
  padding: 4em 0;
  position: relative;

  // Remove big space after introduction (cf. Gutenberg)
  .Introduction + &{
    padding-top: 0;
  }

  // Background color over
  &.--has-bg{
    color: $white;
    
    &:after{
      @include content();
      @include cover();
      z-index: 0;
      background-color: $orange-crepuscule;
      clip-path: inset(1.5em 1.5em 1.5em round 40px 40px 40px); 

      @include responsive($tabletSmall){
        clip-path: inset(1em 1em 1em round 40px 40px 40px); 
      }
    }

    .visual__container{
      border-radius: 10px;
    }
  }

  // Normal version : 
  // [ title ]      [ image ]
  // [ content ]

  &:not(.--alternative) {
    .container{
      @include flex(center, space-between);

      @include responsive($tabletSmall, max){
        align-items: flex-start;
        flex-direction: column;
        gap: 2em;
      }

      > *{
        width: 100%;

        @include responsive($tabletSmall){
          max-width: get-vw(570);
        }

        @include responsive($deskXXL){
          max-width: 40%;
        }
      }
    }

    .container > .wrapper{
      @include flex(flex-start);
      flex-direction: column;
      gap: 2em;
    }

    .container > .visual__container{
      @include imgRatio(570, 800);

      @include responsiveMinMax($mobile, $tabletSmall){
        max-width: 40%;
      }
    }
  }

  // [ image ]      [ title ]       
  //                [ content ]
  .MonasteryContent &:not(.--alternative):not(.--inverted):nth-child(even),
  &:not(.--alternative).--inverted{
    .container{
      @include responsive($tabletSmall){
        flex-direction: row-reverse;
      }
    }
  }

  // Alternative version :
  // [title]   [content]
  // [      image      ]
  &.--alternative{
    .container > .wrapper{
      @include flex(flex-start);
      gap: 2em;

      @include responsive($tabletSmall, max){
        flex-direction: column;
      }

      > *{
        width: 100%;
      }

      h2{
        @include responsive($tabletSmall){
          max-width: get-vw(570);
        }

        @include responsive($deskXXL){
          max-width: 570px;
        }
      }
      
      .content{
        @include responsive($tabletSmall){
          max-width: get-vw(720);
          margin-left: auto;
        }
  
        @include responsive($deskXXL){
          // max-width: 720px;
          max-width: 50%;
        }

        .wswyg--content{
          color: $ardoise;

          &.--content{
            filter: opacity(0.6);
          }
        }
      }
    }

    .container  > .visual__container{
      @include imgRatio(1320, 606);
      margin-top: 3.5em;
    }
  }

  .container{
    z-index: 1;
  }

  .wswyg--content.--chapo{
    font-size: 22px;
    @include font-limited(24);
    margin-bottom: 0.83em;
  }
}

.Input{
  text-align: left;
  
  > input{
    padding: 1.375em 1.625em;
    width: 100%;
    border: 1.5px solid $bleu-marine-l3;
    border-radius: 20em;

    &[type="search"]::-webkit-search-decoration,
    &[type="search"]::-webkit-search-cancel-button,
    &[type="search"]::-webkit-search-results-button,
    &[type="search"]::-webkit-search-results-decoration {
      display: none;
    }

    &::placeholder{
      color: $ardoise;
      opacity: 0.5;
    }
  }
}

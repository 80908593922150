.Timeline{
  position: relative;
  background-color: $ardoise-d2;
  overflow: clip;

  &:before{
    @include content();
    position: absolute;
    top: 0; left: 50%;
    transform: translate(-50%, 0);
    height: 100%;
    width: 1px;
    background-color: rgba(#F5F7F4, 0.1);
  }
}

.Textarea{
  @include responsive($tabletSmall){
    grid-column: span 2;
  }

  textarea{
    width: 100%;
    padding: 1.375em 1.625em;
    min-height: 12.5em;
    border: 1.5px solid $bleu-marine-l3;
    border-radius: 30px;

    &::placeholder{
      color: $ardoise;
      opacity: 0.5;
    }
  }
}

.BannerAlt{
  position: relative;
  overflow: hidden;
  background-color: $white;

  @include responsive($tabletSmall, max){
    padding-top: 200px;
    padding-bottom: 0;
  }

  @include responsive($tabletSmall){
    padding: calc(100vw / 24) 0;
  }

  &.--blue{
    .wrapper{
      background-color: $bleu-marine-l1;
    }
  }

  &.--green{
    .wrapper{
      background-color: $vert-prairie-l1;
    }
  }

  &.--push-right{
    .wrapper{
      @include responsive($tabletSmall){
        margin-left: auto;
      }
    }
  }

  .container {
    z-index: 1;
    
    @include responsive($tabletSmall, max){
      padding: 0 1em;
    }
  }

  .container > .wrapper{
    @include flex(center, center);
    flex-direction: column;
    width: 100%;
    padding: 4em 2.7em 3.5em;

    @include responsive($tabletSmall){
      max-width: get-vw(550);
      min-height: get-vw(740);
      }
      
      @include responsive($deskXXL){
        max-width: 550px;
        min-height: 740px;
    }

    > h2{
      font-size: 30px;
      @include font-limited(60);
      text-transform: uppercase;
      line-height: 0.9;
      text-align: center;
      
      strong{
        font-size: 30px;
        @include font-limited(60);
        line-height: inherit;
      }
    }

    > span{
      font: italic 600 20px $fraunces;
      @include font-limited(30);
      line-height: 0.9;
      text-align: center;
      display: block;
      margin: 0.5em 0 1em;
    }

    > .wswyg--content{

    }

    .Btn{

    }
  }

  > .visual__container{
    @include cover();

    @include responsive($tabletSmall, max){
      max-height: 250px;
    }
  }
}

.ResourceCard{
  display: block;
  background-color: $pierre-blanche;
  padding: 10px;
  position: relative;
  height: 100%;
  transition: transform 750ms $ease;

  .RelatedResources &{
    background-color: $white;
  }

  .Resources &{
    &.--featured{
      @include responsive($mobile){
        grid-column: span 2;
      }

      @include responsive($mobile){
        > .visual__container{
          @include imgRatio(640, 400);

          &:after{
            @include content();
            @include cover();
            background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.9) 100%);
          }
        }

        > .wrapper{
          position: absolute;
          bottom: 0;
          left: 0;
          color: $white;
          padding: 2em;
          width: 100%;

          @include responsive($tabletSmall){
            max-width: 90%;
          }

          > span{
            font-size: 22px;
            @include font-limited(28);
            color: inherit;
          }
        }
      }
    }
  }

  @include noTouchHover() {
    transform: scale(0.97);

    > .visual__container{
      .visual{
        transform: scale(1.05);
      }
    }
  }

  > .visual__container{
    @include imgRatio(295, 200);
    border-radius: 22px;

    .visual{
      transition: transform 750ms $ease;
    }
  }

  > .Categories{
    position: absolute;
    top: 1.25em;
    left: 1.25em;
    color: $white;
  }

  > .wrapper{
    padding: 10px 15px 20px;
    color: $vert-eau;

    > * {
      display: block;
    }

    > time{
      font: 400 14px $fraunces;
      @include font-limited(14);
      line-height: 1.7;
      margin-bottom: 0.15em;
    }

    > span{
      font: 500 18px $main-font;
      @include font-limited(18);
      margin: 0.1em 0 0.55em;
      color: $ardoise;
    }

    > p{
      font: 500 16px $main-font;
      @include font-limited(16);
    }
  }
}
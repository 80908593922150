.PageHeaderGutenberg {
  > .container {
    z-index: 1;
  }

  &.--with-visual{
    > .visual__container {
      z-index: 0;
      pointer-events: none;
    
      &, 
      &:after{
        @include cover();
      }

      &:after{
        @include content();
        background-color: rgba($black, 0.4);
      }
    }
  }

  .titles{
    max-width: 65%;
  }
}
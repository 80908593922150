.Introduction{
  position: relative;
  overflow: hidden;
  background-color: $pierre-blanche;
  color: $ardoise;

  // Special styles for Homepage
  .Home &{
    .container > h2 {
      font: 500 24px $main-font;
      @include font-limited(55);
      line-height: 1;
      color: inherit;
      text-transform: initial;
    }

    .container > .content{
      @include responsive($tabletSmall){
        max-width: get-vw(480);
        margin-left: auto;
      }

      @include responsive($deskXXL){
        max-width: 480px;
      }

      .wswyg--content.--content{
        filter: none;
      }
    }
  }

  // Special styles for History page 
  .History &{
    background-color: $ardoise-d1;
    color: $white;

    .container > h2{
      color: $miel-dore;
    }
  }

  // Special styles for Gutenberg page
  .Gutenberg &{
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 3.5em;

    .introduction-container{
      position: relative;
      background-color: $pierre-blanche;
      z-index: 1;
      padding-top: 3.5em;
      padding-bottom: 2.5em;
      border-radius : 0 0 30px 30px;
      overflow: hidden;
    }
  }

  // Container
  .container{
    @include flex(flex-start);
    gap: 2.5em;
    z-index: 1;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > * {
      width: 100%;
    }
  }

  // Title
  .container > h2{
    color: $ardoise;
    flex: 1;
  }

  // Content
  .container > .content{
    display: grid;
    grid-gap: 1em;

    @include responsive($tabletSmall){
      max-width: get-vw(700);
      margin-left: auto;
    }

    @include responsive($deskXXL){
      // max-width: 700px;
      max-width: 60%;
    }

    .wswyg--content.--chapo{
      font-size: 20px;
      @include font-limited(24);
    }

    .wswyg--content.--content{
      filter: opacity(0.6);
    }

    .Btn{
      justify-self: start;
    }
  }

  // Illustrations
  .visual__container.illustration_2{
    position: absolute;
    opacity: 0.5;
    pointer-events: none;

    .Gutenberg &{
      opacity: 0.1;
    }

    @include responsive($tabletSmall, max){
      bottom: 0; left: 0;
      height: 100%; width: 100%;
    }
      
    @include responsive($tabletSmall){
      max-width: get-vw(648);
      bottom: -5%; left: -20%;
      // opacity: 0.05;
    }

    @include responsive($deskXXL){
      max-width: 648px;
    }
  }
}

.IntroductionBanner{
  @include imgRatio(1440, 600);
  border-radius: 0 0 30px 30px;
  margin-top: -2em;
  z-index: 0;
}

$tarteaucitron-bgcolor: $black;

#tarteaucitronRoot {
  &.tarteaucitronBeforeVisible {
    #tarteaucitronAlertBig {
      @include responsive(520px) {
        display: flex!important;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }

  #tarteaucitronBack {
    background: $black !important;
    opacity: 0.9!important;
  }

  #tarteaucitronAlertBig {
    box-sizing: border-box !important;
    background: $white !important;
    color: $black !important;
    // padding: 40px !important;
    padding: 1.5em !important;
    left: 8px !important;
    right: 8px !important;
    bottom: 8px !important;
    border-radius: 20px;
    width: auto !important;
    margin: 0 !important;
    position: relative;
    
    @include responsive(520px) {
      width: 100% !important;
      max-width: 500px;
      left: 50% !important;
      bottom: 20px !important;
      margin: 0;
      transform: translate(-50%, 0);
    }

    .tarteaucitronCheck,
    .tarteaucitronCross{
      display: none !important;
    }

    #tarteaucitronDisclaimerAlert {
      margin-right: auto!important;
      font: 700 16px $main-font;
      line-height: 1.2;
      color: $base;
      display: block;
      text-align: center;
      padding: 0;

      max-width: 25em;
      min-width: auto;
      margin: 0 auto 0.5em;
    }

    > button {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      gap: 0.7em;
      border-radius: 20em;
      padding: 1.14em 2em !important;
      background: $black !important;
      color: $white !important;
      // margin-top: 10px;
      margin: 0.5em;
      transition: transform 450ms $ease,
                  box-shadow 450ms $ease;

      font: 700 0.77em $main-font !important;

      &:hover{
        transform: scale(0.95);
      }

      // &.tarteaucitronAllow{
      //   background: $vert-prairie !important;
      // }

      // &.tarteaucitronDeny{
      //   background: $orange-crepuscule !important;
      // }
    }
  }

  #tarteaucitronClosePanel {
    background: $tarteaucitron-bgcolor!important;
    border-color: $tarteaucitron-bgcolor!important;
  }

  #tarteaucitronServices {
    box-shadow: none!important;

    .tarteaucitronMainLine,
    .tarteaucitronTitle button {
      background: $tarteaucitron-bgcolor!important;
      border-color: $tarteaucitron-bgcolor!important;
    }

    .tarteaucitronAllow, .tarteaucitronDeny {
      margin: 0!important;
      background: $white !important;
      font-size: 9px!important;
      letter-spacing: 0.1em;
      font-weight: bold;
      margin-left: 10px!important;
      padding: 10px 20px!important;
      color: $tarteaucitron-bgcolor!important;
      text-transform: uppercase;

      &:hover {
        opacity: 1;
      }

      span {
        display: none!important;
      }
    }

    ul .tarteaucitronLine.tarteaucitronIsAllowed { border-color: $valid-color!important; }
    ul .tarteaucitronLine.tarteaucitronIsDenied { border-color: $error-color!important; }

    #tarteaucitronInfo {
      background: 0!important;
    }
  }
}

.HeaderNavBlock{
  color: $ardoise-d2;

  &.--monastery{
    @include responsive($tabletSmall){
      max-width: get-vw(210);
    }

    @include responsive($deskXXL){
      max-width: 210px;
    }
  }

  &.--chartreuse{
    @include responsive($tabletSmall){
      max-width: get-vw(270);
    }

    @include responsive($deskXXL){
      max-width: 270px;
    }
  }

  &.--visit{
    @include responsive($tabletSmall){
      max-width: get-vw(260);
    }

    @include responsive($deskXXL){
      max-width: 260px;
    }

    > span{
      @include responsive($tabletSmall){
        max-width: 80%;
      }
    }
  }

  &.--accompaniment{
    @include responsive($tabletSmall){
      max-width: get-vw(250);
    }

    @include responsive($deskXXL){
      max-width: 250px;
    }
  }

  > span.chapo{
    line-height: 1.2;
    margin-bottom: 0.75em;
  }

  > ul {
    li {
      a{
        color: inherit;
      }
    }
  }
}

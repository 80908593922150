.RelatedResources{
  .container{
    @include flex();
    flex-direction: column;
    gap: 2em;
  }

  .container > .swiper{
    width: 100%;

    &[data-inview].--visible{
      .swiper-slide{
        opacity: 1;
        transform: translate(0, 0);
      }
    }

    .swiper-slide{
      max-width: 320px;
      transform: translate(100%, 0);
      opacity: 0;
      transition: opacity 1250ms $ease,
                  transform 1250ms $ease;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(320);
      }

      @for $i from 1 to 10 {
        &:nth-child(#{$i}) { transition-delay: $i * 75ms; }
      }
    }

    .swiper-navigation{
      @include responsive($tabletSmall){
        position: absolute;
        bottom: 100%; right: 0;
        transform: translate(0, -110%);
      }
    }
  }  

  .container > .Btn{
    align-self: center;
  }
}

.CheckboxFilter{
  position: relative;
  display: inline-block;
  cursor: pointer;

  // For type: input
  > input{
    @include cover();
    opacity: 0;

    @include noTouchHover() {
      &:not(:checked) {
        & ~ span{
          border-color: $bleu-marine-l2;
        }
      }
    }

    &:checked {
      & ~ span{
        color: $bleu-marine;
        border-color: $bleu-marine;
      }

      & ~ .circle {
        transform: translate(20%, 80%) scale(1);
      }
    }
  }

  // For type: link (cf. woocommerce Archive)
  &[href] {
    > span{
      border-color: $bleu-marine-l3;
    }

    > .circle{
      background-color: $orange-crepuscule;
    }
  }

  &[href].--active{
    > span{
      color: $orange-crepuscule;
      border-color: $orange-crepuscule;
    }

    > .circle {
      transform: translate(20%, 80%) scale(1);
    }
  }

  > span{
    font: 600 16px $main-font;
    @include font-limited(16);
    color: $ardoise;
    padding: 0.64em 1em;
    border-radius: 20em;
    border: 2px solid $bleu-marine-l3;
    transition: color 500ms $ease,
                border-color 500ms $ease;
  }

  > .circle{
    @include flex(center, center);
    position: absolute;
    bottom: 100%; right: 0;
    transform: translate(20%, 80%) scale(0);
    height: 18px;
    width: 18px;
    background-color: $bleu-marine;
    border-radius: 50%;
    outline: 2px solid $white;
    transition: transform 250ms $ease;

    @include responsiveMinMax($tabletSmall, $deskXXL){
      height: get-vw(18);
      width: get-vw(18);
    }
  }
}

.NotFound{
  background: #1C1F20;
  color: $white;

  .container,
  .container > *{
    @include flex(center, center);
    flex-direction: column;
  }
  
  .container{
    text-align: center;
    height: 100dvh;

    h1{
      font: 900 100px $fraunces;
      @include font-limited(150);
      color: $miel-dore;
    }
  }
}

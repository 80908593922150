.Banner{
  position: relative;
  background-color: $black;

  @include responsive($tabletSmall) {
    padding: get-vw(180) 0;
  }

  &.--push-right{
    .container > .wrapper{
      margin-left: auto;
    }
  }

  &.--push-center{
    .container > .wrapper{
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;

      h2{
        text-align: center;
      }
    }
  }

  .container{
    z-index: 1;
  }

  .container > .wrapper{
    @include flex(flex-start);
    flex-direction: column;
    width: 100%;
    color: $white;
    
    @include responsive($tabletSmall){
      max-width: get-vw(670);
    }

    @include responsive($deskXXL){
      max-width: 670px;
    }

    h2{
      font-size: 30px;
      @include font-limited(60);
    }

    // Bigger styles for the homepage
    .Home &{
      @include responsive($tabletSmall){
        max-width: get-vw(900);
        margin: 0 auto;
      }

      @include responsive($deskXXL){
        max-width: 900px;
      }

      h2{
        font-size: 50px;
        @include font-limited(80);
        line-height: 0.8;

        i{
          font-size: 1em;

          &:nth-of-type(1){
            font-size: 1.2em;
          }
        }
      }

      .Btn{
        margin-top: 2em;
      }
    }
  }

  .visual__container{
    z-index: 0;

    &, &:after{
      @include cover();
    }

    &:after{
      @include content();
      background: linear-gradient(-90deg, rgba($black, 0.6) 0%, rgba($black, 0) 100%);

      .Home &{
        background: rgba(#11141D, 0.4);
      }
    }
  }
}

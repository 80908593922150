.Sharing{
  > span{
    font: 400 16px $fraunces;
    @include font-limited(16);
    color: $bleu-marine;
    filter: opacity(0.5);
    margin-bottom: 0.625em;
  }
   
  ul{
    @include flex(center, flex-start);
    flex-wrap: wrap;
    gap: 0.5em;
  }
}
.FeaturedEventCard{
  display: block;
  border-radius: 15px;
  padding: 0.3em;
  transition: transform 750ms $ease;

  // background-color: $bleu-marine;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='290' height='311' viewBox='0 0 290 311' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0H290V180C286.686 180 284 182.686 284 186C284 189.314 286.686 192 290 192V311H0V192C3.31371 192 6 189.314 6 186C6 182.686 3.31371 180 0 180V0Z' fill='%2311437D'/%3E %3C/svg%3E ");
  background-size: cover;
  background-repeat: no-repeat;
  
  @include noTouchHover() {
    transform: scale(0.97);

    .visual__container{
      .visual{
        transform: scale(1.05);
      }
    }
  }

  .visual__container{
    @include imgRatio(278, 163);
    border-radius: 11px;

    .visual{
      transition: transform 750ms $ease;
    }
  }

  > .wrapper{
    @include flex(flex-end, space-between);
    color: $white;
    padding: 1.7em 1.15em 2.5em;

    .Categories{
      position: absolute;
      top: 0.5em;
      left: 0.5em;
    }

    .content{
      width: 100%;
      max-width: 80%;
      
      @include responsive($tabletSmall){
        max-width: 75%;
      }

      time{
        font: 700 12px $main-font;
        @include font-limited(12);
        text-transform: uppercase;
        line-height: 0.9;
      }

      span{
        font-weight: 500;
      }
      
    }
  }
}

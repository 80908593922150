.FooterContact{
  display: grid;
  align-items: start;
  grid-gap: 0.25em;
  line-height: 1.4;
  margin-top: 3em;
  
  > *:not(.Btn){
    filter: opacity(0.5);
  }

  .Btn{
    justify-self: start;
    margin-top: 0.8em;
  }
}
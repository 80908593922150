.PageHeaderVisitMonastery{ 
  padding-top: 0;
  padding-bottom: 0;
  
  .inner{
    position: relative; 
    z-index: 1;
    background-color: $ardoise-d2;
    color: $white;
    
    .container{
      @include flex(flex-end, space-between);
      gap: 2em;
      overflow: hidden;
      padding-top: 20em;
      padding-bottom: 5em;
    

      @include responsive($tabletSmall, max){
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      h1, 
      .Btn{
        z-index: 1;
      }
    
      h1{
        width: 100%;
        max-width: 500px;

        @include responsiveMinMax($tabletSmall, $deskXXL){
          max-width: get-vw(500);
        }
      }

      > .illustration_2{
        position: absolute;
        z-index: 0;
      
        @include responsive($tabletSmall, max){
          bottom: 0; left: 50%;
          transform: translate(-55%, 0);
        }

        @include responsive($tabletSmall){
          top: 50%; left: 50%;
          transform: translate(-55%, -54%);
          max-width: get-vw(650);
        }

        @include responsive($deskXXL){
          max-width: 650px;
        }
      }
    }
  }

  > .visual-banner{
    overflow: hidden;
    margin-top: -2.5em;

    .visual__container{
      @include imgRatio(1440, 577);
      
      @include responsive($tabletSmall, max){
        min-height: 50vh;
      }
    }
  }

  > .inner,
  > .visual-banner{
    border-radius: 0 0 30px 30px;
  }
}

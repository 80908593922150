.Newsletter{
  width: 100%;
  max-width: 400px;

  @include responsive($tabletSmall){
    max-width: get-vw(430);
    margin-left: auto;
  }

  @include responsive($deskXXL){
    max-width: 430px;
  }

  .head{
    margin-bottom: 1em;

    > span{
      display: block;
      font-family: $fraunces;
    }

    > p{
      font: italic 400 15px $fraunces;
      @include font-limited(15);
      filter: opacity(0.5);
      margin-top: 0.1em;
    }
  }

  form{
    position: relative;

    .row{

    }

    .Input{
      label{
        position: absolute;
        top: -99999px;
        left: -99999px;
      }

      input{
        font-weight: 600;
        border: none;
        color: $ardoise-d1;
        background-color: #DCDEDC;
        
        .Footer &{
          color: $white;
          background-color: rgba($white, 0.1);
        }
        
        @include responsive($tabletSmall){
          padding-right: 10em;
        }

        &::placeholder{
          color: $ardoise;
          opacity: 0.4;
          
          .Footer &{
            color: $white;
          }
        }
      }
    }

    .form-infos{
      margin-top: 1em;

      @include responsive($tabletSmall, max){
        align-items: flex-start;
        text-align: left;
      }

      .Form__actions{
        @include responsive($tabletSmall){
          position: absolute;
          top: 0; right: 0;
          padding: 0.4em;
        }

        .Btn{
          @include font-limited(14);
          background-color: $white;

          > span{
            color: $ardoise-d2;
          }

          > .Icon{
            display: none;
          }

          .Header &{
            @include responsive($menuBreakpoint, max){
              background-color: $ardoise-d2;

              > span{
                color: $white;
              }
            }
          }
        }
      }

      p{
        filter: opacity(0.5);
      }
    }
  }
}

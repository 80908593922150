.Gutenberg {
  background-color: $pierre-blanche;

  // Add some space at the end of the page
  > *:not(.BlockGutenberg):last-child{
    padding-bottom: 5em;
  }

  // Reset margins from container
  .BlockGutenberg {
    // margin-left: -1.5em;
    // margin-right: -1.5em;

    // @include responsive($tabletSmall) {
    //   margin-left: calc(-100vw / 24);
    //   margin-right: calc(-100vw / 24);
    // }
  }

  // Basic styles
  // .container.--main {
    > *:not(.BlockGutenberg) {
      width: 100%;
      position: relative;
      max-width: 1920px;
      margin: 0 auto;
      padding: 0 1.5em;

      @include responsive($tabletSmall) {
        padding: 0 calc(100vw / 24);
      }

      & + *:not(.BlockGutenberg) {
        margin-top: 1.25em;
      }
    }

    iframe {
      border-radius: 30px;
      overflow: hidden;
    }

    > p + ul,
    > p + ol {
      margin-top: 0;
    }

    > ul {
      padding-left: 1em;
      list-style: disc;
    }

    > ol {
      padding-left: 1.25em;
      list-style-type: decimal;
    }

    > ul,
    > ol {
      code {
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }
  // }

  // Basic blocks
  .wp-block-columns {
    gap: 3em;
  }

  .wp-block-column {
    .wp-block-image {
      height: 100%;
      width: 100%;
    }
  }

  .wp-block-image {
    border-radius: 10px;
    overflow: hidden;

    &.size-large {
      img {
        width: 100%;
      }
    }

    &,
    > figure {
      img {
        height: 100% !important;
        width: 100% !important;
        object-fit: cover;
      }
    }

    > figure {
      margin: 0;
      height: 100%;
      width: 100%;
    }
  }

  .wp-block-embed {
    display: flex;

    &.alignleft {
      justify-content: flex-start;
    }

    &.aligncenter {
      justify-content: center;
    }

    &.alignright {
      justify-content: flex-end;
    }

    .wp-block-embed__wrapper {
      aspect-ratio: 16/9;
      position: relative;
      width: 100%;
      max-width: 700px;

      > iframe {
        @include cover();
      }
    }
  }

  .wp-block-buttons {
    gap: 1em;
  }

  .wp-block-button__link.wp-element-button {
  }

  .wp-block-gallery {
    justify-content: center !important;
    gap: 1em !important;
    margin-top: 2em;

    &,
    &.has-nested-images,
    &.is-cropped,
    &.has-nested-images.is-cropped{
      figure.wp-block-image:not(#individual-image) a,
      figure.wp-block-image:not(#individual-image) img{
        object-fit: contain !important;
      }
    }

    > .wp-block-image{
      border-radius: 20px;
      border: 1px solid #D9D9D9;
      padding: 2em;
      flex: 1 0 calc(100% - 1em) !important;
      max-width: calc(100% - 1em) !important;

      @include responsive($mobile){
        flex: 1 0 calc(50% - 1em) !important;
        max-width: calc(50% - 1em) !important;
      }

      @include responsive($tabletSmall){
        flex: 1 0 calc(20% - 1em) !important;
        max-width: calc(20% - 1em) !important;
      }
    }
  }

  .wp-block-gallery figure.wp-block-image figcaption,
  figure.wp-block-image figcaption{
    font: italic 600 16px $fraunces !important;
    color: $base !important;
    padding: 1em 0 !important;
    margin: 0 !important;
    background: rgba(255, 255, 255, 0.25) !important;
    backdrop-filter: blur(10px);
  }
}

/* -----
Paths
----- */

$base-images: "../images/";
$base-fonts: "../fonts/";

/* -----
Colors
----- */
$white: #ffffff;
// $black: #000000;
$grey: #999999;
$grey-l: #eeeeee;
$gold: #af904d;
$base: #1a1a1a;
$base-d: #141414;
$links-color: $gold;
$error-color: #dc3838;
$valid-color: #5da854;

/* -----
Custom Colors
----- */
$black: #1A1A1A;
$white: #FFFFFF;
$pierre-blanche: #F5F7F4;

$ardoise: #383B44;
$ardoise-d1: #1D1F25;
$ardoise-d2: #15171B;

$bleu-marine: #11437D;
$bleu-marine-l1: #78AAE5;
$bleu-marine-l2: #B8C7D8;
$bleu-marine-l3: #E7ECF2;

$vert-prairie: #52AE32;
$vert-prairie-l1: #A9D96C;
$vert-prairie-l2: #CBE7C1;
$vert-prairie-l3: #EEF7EA;
$vert-eau: #8DA4A4;

$orange-crepuscule: #EC682C;
$orange-crepuscule-l1: #FFA869;
$orange-crepuscule-l2: #F9D2C0;
$orange-crepuscule-l3: #FDF0EA;

$miel-dore: #C5AA4F;

// Themes in : _btn.scss
$buttons-themes: 
  // Slug            // Background            // Color            // Hover Background
  'white'           $white                    $black              $pierre-blanche,
  'black'           $black                    $white              #000000,
  'orange'          $orange-crepuscule        $white              #de6127,
  'blue_l'          $bleu-marine-l1           $white              #6390c2,
  'blue_marine'     $bleu-marine              $white              #103f75,
  'miel_dore'       $miel-dore                $black              #ad9444,
  'green'           $vert-prairie-l1          $black              #9fcc65,
;

// Themes in : _tale-card.scss
$tales-themes: 
  // Slug            // Background            // Color
  'blue'            $bleu-marine              $white,                    
  'green'           $vert-prairie-l1          $black,     
  'grey'            $ardoise                  $white,  
  'orange'          $orange-crepuscule        $white
;

// Themes in : _heritage-card.scss
$heritage-themes: 
  // Slug            // Background            // Color
  'blue'            $bleu-marine              $white,                    
  'green'           $vert-prairie-l1          $white,     
  'grey'            $ardoise                  $white,  
  'orange'          $orange-crepuscule        $white
;

// Themes in : _BlockPageHeaderWithRosace.scss
$page-header-with-rosace-themes: 
  // Slug            // Background            // Color
  'blue_l'           $bleu-marine-l1          $white,                    
  'green'            $vert-prairie-l1         $white,     
  'ardoise'          $ardoise-d2              $white,  
  'miel'             $miel-dore               $ardoise-d1,
  'orange'           $orange-crepuscule       $white,
;

/* -----
Fonts
----- */

$main-font: 'UncutSans', sans-serif;
$fraunces: 'Fraunces', sans-serif;

/* -----
Grid
----- */

$base-vw: 1440;
$base-grid: 24;
$browser-context: 20;

/* -----
CSS Easings
----- */

$transitionDuration : 600ms;
$ease: cubic-bezier(0.36, 0.33, 0, 1);
$snap: cubic-bezier(0,1,.5,1);
$easeOutCubic: cubic-bezier(.215,.61,.355,1);
$easeInOutCubic: cubic-bezier(.645,.045,.355,1);
$easeInCirc: cubic-bezier(.6,.04,.98,.335);
$easeOutCirc: cubic-bezier(.075,.82,.165,1);
$easeInOutCirc: cubic-bezier(.785,.135,.15,.86);
$easeInExpo: cubic-bezier(.95,.05,.795,.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1,0,0,1);
$easeInQuad: cubic-bezier(.55,.085,.68,.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInQuart: cubic-bezier(.895,.03,.685,.22);
$easeOutQuart: cubic-bezier(.165,.84,.44,1);
$easeInOutQuart: cubic-bezier(.77,0,.175,1);
$easeInQuint: cubic-bezier(.755,.05,.855,.06);
$easeOutQuint: cubic-bezier(.23,1,.32,1);
$easeInOutQuint: cubic-bezier(.86,0,.07,1);
$easeInSine: cubic-bezier(.47,0,.745,.715);
$easeOutSine: cubic-bezier(.39,.575,.565,1);
$easeInOutSine: cubic-bezier(.445,.05,.55,.95);
$easeInBack: cubic-bezier(.6,-.28,.735,.045);
$easeOutBack: cubic-bezier(.175, .885,.32,1.275);
$easeInOutBack: cubic-bezier(.68,-.55,.265,1.55);
$easeSwiper: cubic-bezier(0.36, 0.33, 0, 1);

// GSAP Easings
$Power0EaseNone: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$Power1EaseIn: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$Power1EaseOut: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$Power1EaseInOut: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$Power2EaseIn: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$Power2EaseOut: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$Power2EaseInOut: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$Power3EaseIn: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$Power3EaseOut: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$Power3EaseInOut: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$Power4EaseIn: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$Power4EaseOut: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$Power4EaseInOut: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$CircEaseIn: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$CircEaseOut: cubic-bezier(0.075, 0.820, 0.165, 1.000); // wip
$CircEaseInOut: cubic-bezier(0.785, 0.135, 0.150, 0.860); // wip
$ExpoEaseIn: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ExpoEaseOut: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ExpoEaseInOut: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$SineEaseIn: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$SineEaseOut: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$SineEaseInOut: cubic-bezier(0.445, 0.050, 0.550, 0.950);

/* -----
Media Queries
----- */

$mobileSmall: 330px;
$mobile: 550px;
$tabletSmall: 768px;
$tablet: 992px;
$desk: 1200px;
$deskXL: 1460px;
$deskXXL: 1680px;

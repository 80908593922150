.Categories{
  @include flex();
  flex-wrap: wrap;
  gap: 0.25em;

  li{
    font-weight: 600;
    background-color: rgba($white, 0.2);
    backdrop-filter: blur(8px);
    border-radius: 20em;
    padding: 0.75em 1.125em;
  }
}

.Nav {
  @include flex(center);
  gap: 1em;

  body:not(.--animating) &{
    pointer-events: all;
  }

  @include responsive($menuBreakpoint, max) {
    display: none;
  }

  ul {
    @include flex(center);
    gap: 1em;

    li {
      .Link{
        @include font-limited(18);
        font-weight: 600;

        body:not(.--show-menu) .Header.--dark:not(.--scroll) &{
          @include responsive($menuBreakpoint){
            color: $ardoise;
          }
        }

        &.--active{
          cursor: not-allowed;
        }

        body.--show-menu &{
          @include responsive($menuBreakpoint){
            color: $ardoise-d2;
          }
        }
      }
    }
  }
}

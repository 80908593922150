.EventCard{
  @include imgRatio(320, 240);
  display: block;
  overflow: hidden;
  position: relative;
  transition: transform 750ms $ease;

  @include noTouchHover(){
    transform: scale(0.97);

    > .visual__container{
      &:after{
        opacity: 0.75;
      }

      .visual{
        transform: scale(1.05);
      }
    }
  }

  > .visual__container{
    &, 
    &:after{
      @include cover();
    }

    &:after{
      @include content();
      background: linear-gradient(-180deg, rgba($black, 0) 0%, $black 100%);
      transition: opacity 750ms $ease;
    }
      
    .visual{
      transition: transform 750ms $ease;
    }
  }

  > .wrapper{
    @include cover();
    @include flex(flex-end, space-between);
    color: $white;
    padding: 30px;

    .Categories{
      position: absolute;
      top: 0.5em;
      left: 0.5em;
    }

    .content{
      width: 100%;
      max-width: 80%;
      
      @include responsive($tabletSmall){
        max-width: 75%;
      }

      > *{
        display: block;
      }

      time{
        font: 700 12px $main-font;
        @include font-limited(12);
        text-transform: uppercase;
        line-height: 0.9;
        margin-bottom: 0.8em;
      }

      span{
        font-weight: 500;
      }
      
    }
  }
}

.Form{
  text-align: left;

  &.--contact{
    padding-top: 2em;

    .grid,
    .row{
      display: grid;
      grid-gap: 2em 1.5em;

      @include responsive($tabletSmall){
        grid-template-columns: repeat(2, 1fr);
      }

      > *.--full{
        @include responsive($tabletSmall){
          grid-column: span 2;
        }
      }
    }
  }

  label:not(.Checkbox){
    font: 1000 16px $main-font;
    @include font-limited(16);
    display: block;
    margin-bottom: 0.75em;
    color: $ardoise;

    sup,
    abbr{
      color: $miel-dore;
    }
  }

  .input--full {
    grid-column: 1 / -1;
  }

  .--error {
    font-size: 0.75em;
    color: $error-color;
    margin-top: 0.15em;
  }

  .Form__group,
  .Form__actions{
    *[disabled]{
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .Form__actions{
    .Btn{
      &[disabled] {
        pointer-events: none;
      }

      .Contact &{
        background-color: $miel-dore;

        > span{
          color: $black;
        }

        > .Icon.--arrow svg > *{
          stroke: $black;
        }
      }
    }
  }

  .Form__output{
    &:not(:empty) {
      font: 400 14px $main-font;
      @include font-limited(14);
      color: $valid-color;
      text-align: right;
      margin-top: 2em;

      .Newsletter &{
        text-align: left;
        display: inline-block;
        padding: 1em;
        border-radius: 10px;
        background: #7da237;
        color: $white;
      }

      .Contact &{
        font: 400 18px $main-font;
        @include font-limited(18);
      }
    }
  }
}


.variations_form {
  flex-direction: column;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
  background: transparent !important;
  opacity: 1;
}
.MainLogo{
  @include imgRatio(150, 117);
  position: relative;
  width: 150px;
  transition: width 500ms $ease;

  @include responsiveMinMax($tabletSmall, $deskXXL){
    width: get-vw(150);
  }

  // min-width: 140px;
  // max-width: 140px;
  
  // @include responsive($tabletSmall){
  //   min-width: get-vw(150);
  //   max-width: get-vw(150);
  // }

  // @include responsive($deskXXL){
  //   min-width: 150px;
  //   max-width: 150px;
  // }

  .Header &{
    width: 80px;

    @include responsive($tabletSmall){
      width: get-vw(130);
    }

    @include responsive($deskXXL){
      width: 130px;
    }
  }

  svg{
    @include cover();

    .illustration > *,
    .title > *,
    .baseline > *{
      fill: $white;
    }
  }

  .Header__main &{
    svg {
      .illustration > *,
      .title > *{
        body.--show-menu &{
          fill: $bleu-marine;
        }
      }

      .baseline > *{
        body.--show-menu &{
          fill: $orange-crepuscule;
        }
      }
    }
  }

  body:not(.--show-menu) .Header.--dark:not(.--scroll) .Header__main &{
    svg {
      .illustration > *,
      .title > *,
      .baseline > *{
        fill: $ardoise;
      }

      .illustration > path{
        stroke: $ardoise;
      }
    }
  }
}

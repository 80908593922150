.TaleCard{
  @include imgRatio(320, 420);
  position: relative;
  background-color: $pierre-blanche;
  overflow: hidden;
  grid-column: 1/8;

  @include responsive($tabletSmall){
    grid-column: span 3;
  }

  @each $slug, $background, $color in $tales-themes {
    &[data-color="#{$slug}"]{
      .wrapper{
        background-color: $background;
        color: $color;
      }
    }
  }

  @include noTouchHover() {
    .visual__container{
      .visual{
        transform: scale(1.05);
      }
    }
  }

  &:nth-child(1){
    @include responsive($tabletSmall, max){
      grid-column: 1/4;
    }
  }

  &:nth-child(2){
    @include responsive($tabletSmall, max){
      grid-column: 4/8;
    }
  }

  &:nth-child(3){
    @include responsive($tabletSmall, max){
      @include imgRatio(360, 250);
    }
  }

  &:nth-child(4){
    @include responsive($tabletSmall, max){
      grid-column: 1/5;
    }
  }

  &:nth-child(5){
    @include responsive($tabletSmall, max){
      grid-column: 5/8;
    }
  }
  
  &:nth-child(6){
    @include responsive($tabletSmall, max){
      grid-column: 1/4;
    }
  }
  
  &:nth-child(7){
    @include responsive($tabletSmall, max){
      grid-column: 4/8;
    }
  }
  
  &:nth-child(8){
    @include responsive($tabletSmall, max){
      grid-column: 1/5;
    }
  }
  
  &:nth-child(9){
    @include responsive($tabletSmall, max){
      grid-column: 5/8;
    }
  }

  &:nth-child(1),
  &:nth-child(5),
  &:nth-child(9){
    @include responsive($tabletSmall){
      @include imgRatio(640, 420);
      grid-column: span 6;
    }
  }

  &:not(.--donation){
    .wrapper{
      bottom: 0; left: 0;
      min-height: 110px;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        min-height: get-vw(170);
      }

      @include responsive($tabletSmall){
        min-height: 150px;
      }

      span.title{
        max-width: 270px;

        @include responsiveMinMax($tabletSmall, $deskXXL){
          max-width: get-vw(270);
        }
      }
    }
  }

  &.--donation{    
    @include responsive($tabletSmall){
      @include imgRatio(1320, 420); 
      grid-column: span 12;
    }

    .wrapper{
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: flex-end;
      height: 100%;
      width: 100%;
      max-height: 90%;
      top: 50%; 
      
      @include responsive($tabletSmall, max){
        left: 0; top: auto;
        bottom: 0;
        max-height: none;
        height: auto;
        max-width: none;
        padding: 2.5em 2em 1.25em;
      }
      
      @include responsive($tabletSmall){
        max-width: 480px;
        left: 1em;
        transform: translate(0, -50%);
      }

      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(480);
      }

      span.title{
        font-size: 40px;
        @include font-limited(50);
      }
    }
  }

  &.--transparent{
    .visual__container{
      &:after{
        background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.6) 100%);
        transition: opacity 500ms $ease;
      }
    }

    @include noTouchHover() {
      .visual__container{
        &:after{
          opacity: 0.5;
        }
      }
    }
  }

  &:not(.--transparent):not(.--donation){
    .wrapper,
    .wrapper > span.title{
      transition: transform 500ms $ease;
    }

    @include noTouchHover() {
      .wrapper{
        transform: translate(0, 10%);
      }

      .wrapper > span.title{
        transform: translate(0, -1em);
      }
    }
  }
  
  .wrapper{
    @include flex(flex-end);
    flex-wrap: wrap;
    position: absolute;
    z-index: 1;
    width: 100%;
    color: $white;
    padding: 2.5em 1em 1em;
    
    @include responsive($tabletSmall){
      padding: 4em 1.5em 1.5em;
    }

    span.title{
      font-size: 18px;
      @include font-limited(28);
      line-height: 1;
      display: block;
      width: 100%;
    }
  }

  .visual__container{
    z-index: 0;

    &, &:after{
      @include cover();
    }

    &:after{
      @include content();
    }

    .visual{
      transition: transform 500ms $ease;
    }
  }
}

.EventInfos{
  padding: 5em 0;
  margin-top: -2.5em;

  .container{
    .push-right{
      width: 100%;

      @include responsive($tabletSmall){
        max-width: get-vw(600);
        margin-left: auto;
        padding-right: space(1);
      }
        
      @include responsive($deskXXL){
        max-width: 600px;
      }

      h3{
        font: normal 600 20px $fraunces;
        @include font-limited(26);
        text-transform: initial;
        color: $bleu-marine;
        margin-bottom: 1.38em;
      }

      .grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5em 2.5em;

        > div{
          > *{
            display: block;
          }
          
          span.title{
            font-weight: 700;
            color: $ardoise-d2;
          }

          span.info{
            color: $ardoise;
          }
        }
      }
    }
  }
}
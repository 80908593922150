h1, h2, h3, h4, h5, h6{
	text-transform: uppercase;
}

h1 {
	// font: 700 50px $main-font;
	font: 700 8.5vw $main-font;
	@include font-limited(80);
	line-height: 0.9;
	// text-transform: uppercase;

	i, 
	em{
		font: 600 0.6em $fraunces;
		text-transform: initial;
		line-height: inherit;
	}

	i{
		font-style: italic;
	}

	em{
		font-style: normal; 
	}

	&.--s{
		@include font-limited(60);
	}
		
	&.--xs{
		font-size: 30px;
		@include font-limited(40);
		font-weight: 500;
		text-transform: initial;
		line-height: 1;
	}
}

h2{
	font: 700 30px $main-font;
	@include font-limited(70);
	text-transform: uppercase;
	line-height: 1;
	
	&.--s{
		@include font-limited(50);
	}

	i,
	em{
		font: 600 0.71em $fraunces;
		text-transform: initial;
		line-height: 1;
	}

	i{
		font-style: italic;
	}

	em{
		font-style: normal;
	}
}

// .--mixed{
// 	font: italic 600 30px $fraunces;
// 	@include font-limited(44);
// 	line-height: 1;

// 	strong{
// 		font: 700 41px $main-font;
// 		@include font-limited(70);
// 		text-transform: uppercase;
// 		line-height: inherit;
// 	}
// }

// .--mixed-title{
// 	font: 700 40px $main-font;
// 	@include font-limited(70);
// 	text-transform: uppercase;
// 	line-height: 1;

// 	i{
// 		font: italic 600 30px $fraunces;
// 		@include font-limited(50);
// 		text-transform: initial;
// 		line-height: 1;
// 	}
// }

.--small{
	font: 400 16px $main-font;
	@include font-limited(16);
	line-height: 1;
}

.--extra-small{
	font: 400 12px $main-font;
	@include font-limited(12);
	line-height: 0.9;
}

.tag{
	font: 700 14px $main-font;
	@include font-limited(14);
	// text-transform: uppercase;
	color: $white;
	background: $black;
	border-radius: 20em;
	padding: 0.9em 1.2em;
}

.chapo{
	font: 500 20px $main-font;
	@include font-limited(27);
	line-height: 1.4;
	color: $black;
}

strong {
	font-weight: 700;
}

em {
	font-style: italic;
}

p {
	&.no__results {
		text-align: center;
		opacity: 0.2;
	}
}

.wswyg--content {
	text-align: left;

	* {
		color: inherit;
	}

	> * + * {
		margin-top: 1em;
	}

	h2{
		font: 600 22px $fraunces;
		@include font-limited(28);
	}

	h3{
		font: 600 20px $fraunces;
		@include font-limited(26);
	}

	h4{
		font: 600 18px $fraunces;
		@include font-limited(24);
	}

	h5{
		font: 600 16px $fraunces;
		@include font-limited(22);
	}

	h6{
		font: 600 14px $fraunces;
		@include font-limited(20);
	}

	h2, 
	h3, 
	h4, 
	h5, 
	h6{
		text-transform: initial;
		letter-spacing: -0.02em;
		line-height: 1.3;
	}

	ul,
	ol {
		padding-left: 1em;

		code {
			margin-top: 1em;
			margin-bottom: 1em;
		}
	}

	ul {
		list-style: disc;
	}

	ol {
		list-style-type: decimal;
	}

	figure {
		margin: 1em auto;
		overflow: hidden;

		@include responsive($tabletSmall) {
			margin: space(1) auto;
		}

		&:last-child {
			margin-bottom: 0;
		}

		img {
			display: block;
		}
	}

	blockquote {
		padding-left: 1em;
		border-left: 2px solid $grey;

		p {
			@include font-limited(28);
			line-height: 1.4;
		}
	}

	a {
		font-weight: 700;
		text-decoration: underline;
		// color: $links-color;
	}

	table{
		font-size: 0.75em;

		td, th{
			padding: 1em;
			border: 1px solid rgba($grey-l, 0.25);

			*{
				font: inherit;
			}

			strong{
				font-weight: 700;
			}
		}
	}
}

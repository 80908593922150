.Icon {
  @include imgRatio(1, 1);
  position: relative;

  svg {
    @include cover();
  }
  
  &.--rosace{
    @include imgRatio(100, 100.14);
    @include svg-limited(100);

    svg > *{
      fill: $ardoise;
    }
  }

  &.--arrow{
    @include imgRatio(18, 18);
    @include svg-limited(18);

    svg > *{
      stroke: $white;
    }
  }

  &.--tick{
    @include imgRatio(6, 5);
    @include svg-limited(8);

    svg > *{
      stroke: $white;
    }
  }

  &.--chevron{
    @include imgRatio(12, 7);
    @include svg-limited(12);

    svg > *{ 
      stroke: $bleu-marine; 
    }
  }

  &.--x,
  &.--facebook,
  &.--linkedin,
  &.--instagram,
  &.--youtube{
    @include imgRatio(24, 24);
    @include svg-limited(24);

    svg > * {
      fill: $pierre-blanche;

      .Sharing &{
        fill: $bleu-marine;
      }

      .Header &,
      .Socials.--contact &{
        fill: $ardoise;
      }
    }
  }

  &.--ticket{
    @include imgRatio(18, 18);
    @include svg-limited(18);
  }
  
  &.--donation{
    @include imgRatio(21, 18);
    @include svg-limited(21);

    svg > *{
      stroke: $white;
    }
  }

  &.--basket{
    @include imgRatio(19, 18);
    @include svg-limited(19);

    svg > *{
      stroke: $ardoise;

      &:nth-child(1),
      &:nth-child(2){
        fill: $ardoise;
      }
    }
  }
}

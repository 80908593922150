.Socials {
  &:not(.--contact) {
    > span{
      font-family: $fraunces;
      filter: opacity(0.5);
    }
  }

  &.--contact{
    > span{
      font-weight: 700;
      color: $ardoise;
    }
  }

  > span {
    margin-bottom: 0.8em;
  }

  > ul {
    @include flex(center);
    gap: 0.7em;

    li {
      a {
        @include flex(center, center);
        transition: transform 400ms $ease;

        @include noTouchHover() {
          transform: scale(0.85);
        }
      }
    }
  }
}

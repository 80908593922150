.Dropdown{
  .select-container{
    position: relative;
    
    .Icon.--chevron{
      position: absolute;
      top: 50%; right: 1em;
      transform: translate(0, -50%);
      pointer-events: none;
    }
  }
  
  select.Form__control {
    font: 600 16px $main-font;
    @include font-limited(16);
    line-height: 3.2em;
    height: 56px;
    padding: 0 2.5em 0 1.5em;
    width: 100%;
    border-radius: 20em;
  
    background-color: #EDEFEB;
    color: $ardoise;
  
    @include responsiveMinMax($tabletSmall, $deskXXL) {
      line-height: get-vw(56);
      height: get-vw(56);
    }
  
  }
}

.Btn {
  cursor: pointer;
  font: 600 14px $main-font;
  @include font-limited(18);

  display: inline-flex;
  align-items: center;
  gap: 0.5em;
  background-color: #2C296C;
  color: $white;
  border-radius: 20em;
  padding: 1.14em 2em;
  margin-top: 1.5em;
  
  body:not(.--animating) &{
    transition: background-color 500ms $ease,
                transform 500ms $ease;
  }

  &.--hidden{
    display: none;
  }

  &.--medium{
    padding: 0.75em 1.25em;
  }

  &.--small{
    @include font-limited(15);
    padding: 0.9em 1.5em;
  }

  /*
  * Themes
  */

  @each $slug, $background, $color, $backgroundHover in $buttons-themes {
    &.--#{$slug}{
      background-color: $background;
      
      span{
        color: $color;
      }

      .Icon.--arrow svg > *,
      .Icon.--donation svg > *,
      .Icon.--ticket svg > *{
        stroke: $color;
      }

      @media (pointer: fine) {
        &:hover{
          background-color: $backgroundHover;
        }
      }
    }
  }
  
  /*
  * Hover
  */

  @media (pointer: fine) {
    .--btn-fake:hover &,
    &:hover{
      transform: scale(0.95);

      .Icon.--arrow{
        transform: translate(40%, 0);
      }

      .Icon.--donation{
        transform: rotate(6deg);
      }

      .Icon.--ticket{
        transform: rotate(-10deg);
      }
    }
  }

  > span{
    color: $white;
  }

  > .Icon{
    transition: transform 500ms $ease;
  }
}

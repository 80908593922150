.RelatedProducts{
  overflow: hidden;
  border-top: 1px solid rgba($black, 0.1);

  .container > .head{
    @include flex(flex-end, space-between);
    margin-bottom: 2em;

    @include responsive($tabletSmall, max){
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }

    @include responsive($tabletSmall){
      gap: 2em;
    }
  }

  .container > .products{
    display: grid;
    grid-gap: 2em;

    @include responsive($tabletSmall){
      grid-template-columns: repeat(3, 1fr);
    }

    .product{
      position: relative;

      @include responsive($tabletSmall, max){
        max-width: 500px;
      }

      .woocommerce-loop-product__link{
        // Image
        img{
          width: 100%;
          border-radius: 20px;
          max-height: 400px;
        }

        // Title
        .woocommerce-loop-product__title{
          font: 600 22px $main-font;
          @include font-limited(22);
          color: $ardoise-d2;
          text-transform: initial;
          margin-top: 1em;
          padding-right: 1em;
        }

        // Price
        .price{
          font: normal 600 18px $fraunces;
          @include font-limited(18);
          color: $orange-crepuscule;
          margin-top: 0.1em;
        }

        // More
        .screen-reader-text{
          display: none;
        }
      }

      .add_to_cart_button{
        display: block;
        position: relative;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: $orange-crepuscule;

        position: absolute;
        bottom: 0; right: 0;

        @include responsiveMinMax($tabletSmall, $deskXXL){
          height: get-vw(40);
          width: get-vw(40);
        }

        &:before{
          @include content();
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%, -50%);
          background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 17.0625C7.82843 17.0625 8.5 16.3909 8.5 15.5625C8.5 14.7341 7.82843 14.0625 7 14.0625C6.17157 14.0625 5.5 14.7341 5.5 15.5625C5.5 16.3909 6.17157 17.0625 7 17.0625Z' fill='white'/%3E%3Cpath d='M13.5 17.0625C14.3284 17.0625 15 16.3909 15 15.5625C15 14.7341 14.3284 14.0625 13.5 14.0625C12.6716 14.0625 12 14.7341 12 15.5625C12 16.3909 12.6716 17.0625 13.5 17.0625Z' fill='white'/%3E%3Cpath d='M3.97352 5.0625H16.75L14.7447 11.5812C14.6738 11.8111 14.5311 12.0123 14.3375 12.1552C14.1439 12.2981 13.9095 12.3751 13.6689 12.375H6.91117C6.66667 12.3751 6.42879 12.2956 6.23354 12.1484C6.03829 12.0012 5.8963 11.7944 5.82906 11.5594L3.28586 2.65781C3.25226 2.54033 3.18131 2.43699 3.08376 2.36341C2.98621 2.28983 2.86735 2.25002 2.74516 2.25H1.5625' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background-size: 100% 100%;
          height: 1em;
          width: 1em;
        }
      }
    }
  }
}

.OurResources{
  overflow: hidden;

  .container > .head{
    @include flex(flex-end, space-between);
    margin-bottom: 2em;

    @include responsive($tabletSmall, max){
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }

    @include responsive($tabletSmall){
      gap: 2em;
    }
  }

  .container > .swiper{
    &[data-inview].--visible{
      .swiper-slide{
        opacity: 1;
        transform: translate(0, 0);
      }
    }

    .swiper-wrapper{
      @include responsive($tabletSmall){
        gap: 1.15em;
      }
    }

    .swiper-slide{
      max-width: 315px;
      transform: translate(100%, 0);
      opacity: 0;
      transition: opacity 1250ms $ease,
                  transform 1250ms $ease;

      @include responsiveMinMax($tabletSmall, $deskXXL){
        max-width: get-vw(315);
      }
      
      @for $i from 1 to 10 {
        &:nth-child(#{$i}) { transition-delay: $i * 75ms; }
      }
    }
  }
}
.PageTransition{
  @include cover(fixed);
  z-index: 1000;
  pointer-events: none;

  @include flex(center, center);
  background-color: #101114;
  clip-path: inset(100% 0% 0% 0%);
  will-change: transform;

  > .Icon.--rosace{
    display: block;
  }
}
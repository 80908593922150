.SpecificRequest{
  padding-bottom: 2em;

  .head{
    @include flex(center, center);
    flex-direction: column;
    gap: 1em;
    text-align: center;
  }

  .grid{
    @include flex(flex-start, center);
    flex-wrap: wrap;
    gap: 5em 2em;
    padding: 5em 2.5em;
    margin-top: 3.5em;
    
    @include responsive($tabletSmall){
      padding: 5em;
    }

    > *{
      @include responsive($tabletSmall){
        flex: 1 0 calc(33.33333% - 2em);
        max-width: calc(33.33333% - 2em);
      }
    }
  }
}

.ThumbnailSticky {
  @include responsive($tabletSmall) {
    @include cover();
    z-index: 10;
    padding-top: 10em;
    pointer-events: none;
  }

  .container {
    @include responsive($tabletSmall) {
      padding: 0;
      height: 100%;
    }
  }

  .wrapper {
    pointer-events: all;

    @include responsive($tabletSmall) {
      padding: 0;
      max-width: get-vw(690);

      position: sticky;
      top: 2em;
    }

    @include responsive($deskXXL) {
      max-width: 690px;
    }

    > .visual__container {
      @include imgRatio(690, 450);
      width: 100%;
      max-width: 690px;

      @include responsiveMinMax($tabletSmall, $deskXXL) {
        max-width: get-vw(690);
      }
    }

    > .inner {
      @include flex(flex-start, space-between);
      flex-wrap: wrap;
      gap: 1.5em;
      padding-left: space(1);
      margin-top: 1.5em;

      .Btn {
        margin-top: 0;
      }
    }
  }
}

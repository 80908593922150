.Link {

  position: relative;
  gap: 1em;
  display: inline;
  
  body:not(.--animating) &{
    transition: color 350ms $ease;
  }

  &.--active{
    > span{
      background-size: 100% 1px;
      background-position: 0% 100%;
    }

    > .Icon.--arrow-external{
      transform: scale(0.9) rotate(4.5deg);
    }
  }

  &.--icon{
    display: inline-flex;
  }

  &.--white{
    color: $white;
    
    span{
      background-image: linear-gradient($white, $white);
    }
  }

  // Line height issue
  &.--lh{
    @include noTouchHover() {
      > span{
        background-position: 0% 100%;
      }
    }

    > span{
      background-position: 100% 100%;
    }
  }

  &[download]{
    display: inline-flex;
    align-items: flex-start;

    > span{
      font: 600 16px $main-font;
      @include font-limited(16);
      margin-left: 0.75em;
      padding-top: 0.35em;
    }

    @include noTouchHover() {
      > .Icon.--download{
        transform: scale(0.85);
      }
    }
  }

  > span{
    display: inline;
    line-height: 1;
    background-image: linear-gradient($black, $black);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 0% 1px;
    
    body:not(.--animating) &{
      transition: background-color 350ms $Power3EaseOut,
                  background-size 600ms $ease;
    }
  }

  > .Icon.--arrow-external{
    body:not(.--animating) &{
      transition: transform 350ms $ease 100ms;
    }
  }



  @include noTouchHover() {
    > span{
      background-size: 100% 1px;
      background-position: 0% 100%;
    }

    > .Icon.--arrow-external{
      transform: scale(0.9) rotate(4.5deg);
    }
  }
}
.PageHeaderProduct{
  padding-top: 8em;
  padding-bottom: 5em;

  .container > .inner{
    @include flex(stretch);
    gap: 2.5em;
    padding: 1em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    > * {
      width: 100%;
    }

    > .preview{
      min-height: 100%;
      width: 100%;
      border-radius: 20px;
      overflow: hidden;

      @include responsive($tabletSmall){
        max-width: get-vw(600);
      }

      @include responsive($deskXXL){
        max-width: 600px;
      }

      .swiper{
        height: 100%;

        .swiper-wrapper{
          cursor: initial;
        }

        img{
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    > .wrapper{
      @include flex(flex-start);
      flex-direction: column;
      margin-left: auto;

      @include responsive($tabletSmall){
        max-width: get-vw(600);
      }

      @include responsive($deskXXL){
        max-width: 60%;
      }

      .product_meta{
        order: 0;
        margin-bottom: 1em;

        border-radius: 20em;
        background-color: $black;
        color: $white;
        padding: 0.6em 1em;
        font-weight: 700;
        font-size: 0.7em;
      }

      h1{
        font: 600 30px $main-font;
        @include font-limited(45);
        line-height: 1;
        text-transform: initial;
        color: $ardoise-d2;

        order: 1;
      }

      // Reference
      .sku_wrapper{
        color: $ardoise;
        filter: opacity(0.5);
        order: 2;
        text-transform: uppercase;
        font-size: 0.8em;
        font-weight: 600;
        margin-top: 1em;

        display: none;
      }

      .woocommerce-product-details__short-description{
        order: 3;
        padding: 0.75em 0
      }

      .stock{
        order: 4;
        font-weight: 700;
        margin: 1em 0;

        display: none;
      }

      p.price{
        font: normal 600 30px $fraunces;
        @include font-limited(28);
        line-height: 1.7;
        color: $orange-crepuscule;
        margin-bottom: 0.2em;

        order: 4;
      }

      // Forms
      form{
        position: relative;
        margin-top: 2em;
        
        &.variations_form{
          @include flex();
          gap: 2em;

          .single_add_to_cart_button{
            // @include responsive($tabletSmall){
            //   margin-left: -17.5em;
            // }
          }
        }

        order: 5;

        @include responsive($tabletSmall, max){
          flex-direction: column;
        }

        label, 
        .qty-label{
          font-weight: 600;
          display: block;
          margin-bottom: 0.25em;
        }

        .variations{
          position: relative;

          tr{
            @include flex(flex-start);
            flex-direction: column;

            & + tr{
              margin-top: 1em;
            }
          }

          td.value{
            select{
              font: 400 16px $main-font;
              @include font-limited(16);
              border-radius: 20em;
              background-color: $white;
              padding: 1.375em 3em 1.375em 1.625em;

              text-overflow: ellipsis;
              overflow: hidden !important;
              white-space: nowrap;
              max-width: 280px;

              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23000' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M6 9l6 6l6 -6' /%3E%3C/svg%3E");
              background-size: 20px;
              background-repeat: no-repeat;
              background-position-x: 90%;
              background-position-y: 50%;

              @include responsiveMinMax($tabletSmall, $deskXXL){
                max-width: get-vw(280);
                background-size: get-vw(20);
              }
            }
          }

          .reset_variations{
            font: 400 14px $main-font;
            @include font-limited(14);
            color: $error-color;
            margin: 0.5em;
            display: block;

            position: absolute;
            top: 0; right: 0;
          }

          .price{
            font-weight: 700;
            color: $orange-crepuscule;
            margin: 1em;
          }
        }

        .quantity{
          &__wrapper{
            @include flex(center);

            button{
              display: none;
            }
          }
        }

        .quantity__wrapper,
        .woonp-type-default{
          input{
            font: 400 16px $main-font;
            @include font-limited(16);
            color: $ardoise;
            width: 100%;
            border-radius: 20em;
            background-color: $white;
            text-align: center;
            padding: 1.375em 1.625em;
            
            @include responsive($tabletSmall){
              max-width: get-vw(100);
            }
          }
        }
        
        .woonp-type-default{
          align-items: flex-start;
          flex-direction: column;
          
          input{
            max-width: 150px;
            text-align: left;

            @include responsive($tabletSmall){
              max-width: get-vw(150);
            }
          }
        }

        .single_add_to_cart_button{
          font: 600 18px $main-font;
          @include font-limited(18);
          background-color: $orange-crepuscule;
          color: $white;
          border-radius: 20em;
          padding: 1.2em 2em;
          margin-top: 1em;

          display: inline-flex;
          align-items: center;
          gap: 0.7em;

          body:not(.--animating) &{
            transition: background-color 500ms $ease,
                        transform 500ms $ease;
          }

          @media (pointer: fine) {
            &:hover{
              transform: scale(0.95);
            }
          }

          &:after{
            @include content();
            background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.4375 15.5625C8.4375 16.3564 7.79391 17 7 17C6.20609 17 5.5625 16.3564 5.5625 15.5625C5.5625 14.7686 6.20609 14.125 7 14.125C7.79391 14.125 8.4375 14.7686 8.4375 15.5625Z' fill='white' stroke='white' stroke-width='0.125'/%3E%3Cpath d='M14.9375 15.5625C14.9375 16.3564 14.2939 17 13.5 17C12.7061 17 12.0625 16.3564 12.0625 15.5625C12.0625 14.7686 12.7061 14.125 13.5 14.125C14.2939 14.125 14.9375 14.7686 14.9375 15.5625Z' fill='white' stroke='white' stroke-width='0.125'/%3E%3Cpath d='M3.97352 5.0625H16.75L14.7447 11.5812C14.6738 11.8111 14.5311 12.0123 14.3375 12.1552C14.1439 12.2981 13.9095 12.3751 13.6689 12.375H6.91117C6.66667 12.3751 6.42879 12.2956 6.23354 12.1484C6.03829 12.0012 5.8963 11.7944 5.82906 11.5594L3.28586 2.65781C3.25226 2.54033 3.18131 2.43699 3.08376 2.36341C2.98621 2.28983 2.86735 2.25002 2.74516 2.25H1.5625' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            background-size: 100% 100%;
            height: 1em;
            width: 1em;
          }

          .icon{
            display: none;
          }
        }
      }

      // Message basket
      .woocommerce-notices-wrapper{
        order: 6;
        margin-top: 1.5em;

        .woocommerce-message{
          border-top-color: $vert-prairie;
        }

        .woocommerce-message::before{
          color: $vert-prairie;
        }
      }

      // Variation prices
      .single_variation_wrap{
        @include flex(flex-start);
        flex-direction: column;

        > .woocommerce-variation{
          font-size: 0.7em;
          order: 1;
          margin-top: 1em;
        }
      }
    }
  }
}

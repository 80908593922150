.NavToggle {
  background-color: $white;
  z-index: 10;
  margin-top: 0;
  justify-self: end;
  overflow: hidden;
  pointer-events: all;
  border-radius: 20em;

  body:not(.--show-menu) .Header.--dark &{
    background-color: $pierre-blanche;
  }
  
  body:not(.--animating) &{
    transition: transform 500ms $ease,
                background-color 500ms $ease;
  }

  body.--show-menu &{
    background-color: $ardoise-d2;

    > span{
      color: $white;
    }
  }

  > span {
    color: $black;
    transition: color 500ms $ease;
  }

  > .burger{
    @include flex();
    position: relative;
    margin-top: 0.1em;

    > span,
    &:before,
    &:after{
      height: 1.5px;
      width: 10px;
      background-color: $black;
      border-radius: 20em;
      transition: transform 250ms $ease,
                  background-color 250ms $ease;

      body.--show-menu &{
        background-color: $white;
      }
    }

    &:before,
    &:after{
      @include content();
      position: absolute;
      top: 0; left: 0;
    }

    > span{
      body.--show-menu &{
        transform: scaleX(0);
      }
    }

    &:before{
      transform: translate(0, -0.2em);
      
      body.--show-menu &{
        transform: translate(0, 0) rotate(45deg);
      }
    }
    
    &:after{
      transform: translate(0, 0.2em);

      body.--show-menu &{
        transform: translate(0, 0) rotate(-45deg);
      }
    }

  }
}

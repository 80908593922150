.ContactUs{
  padding-top: 2em;
  padding-bottom: 2em;

  .container{
    @include flex(flex-start);
    gap: 2em;

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }
  }

  .container > .Form{
    width: 100%;

    @include responsive($tabletSmall){
      max-width: get-vw(750);
      margin-left: auto;
    }

    @include responsive($deskXXL){
      // max-width: 750px;
      max-width: 60%;
    }

    .Form__group:last-child{
      margin-top: -1em;
    }
  }
}

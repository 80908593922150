.Footer {
  padding-top: 5em;
  color: $white;

  // Main Nav
  .main {
    @include flex(flex-start);

    @include responsive($tabletSmall, max){
      flex-direction: column;
    }

    .navigation{
      @include flex(flex-start);
      flex-wrap: wrap;
      gap: 4em;
      width: 100%;

      @include responsive($tabletSmall, max){
        margin-top: 4em;
      }

      @include responsive($mobile, max){
        flex-direction: column;
      }

      @include responsive($tabletSmall){
        max-width: get-vw(900);
        margin-left: auto;
        justify-content: space-between;
      }

      @include responsive($deskXXL){
        // max-width: 900px;
        max-width: 70%;
      }

      > .FooterNavBlock,
      > .head > *{
        width: 100%;
    
        @include responsiveMinMax($mobile, $tabletSmall){
          flex: 1 0 calc(50% - 4em);
          max-width: calc(50% - 4em);
        }
      }

      > .head{
        @include flex(flex-start);
        gap: 4em;
        width: 100%;

        @include responsive($mobile, max){
          flex-direction: column;
        }
        
        @include responsive($tabletSmall){
          flex-direction: column;
          gap: 2em;
        }
      }

      > * {
        &:nth-child(1){
          @include responsive($tabletSmall){
            max-width: get-vw(260);
          }

          @include responsive($deskXXL){
            max-width: 260px;
          }
        }

        &:nth-child(2){
          @include responsive($tabletSmall){
            max-width: get-vw(260);
          }

          @include responsive($deskXXL){
            max-width: 260px;
          }
        }

        &:nth-child(3){
          @include responsive($tabletSmall){
            max-width: get-vw(194);
          }

          @include responsive($deskXXL){
            max-width: 194px;
          }
        }
      }
    }
  }

  // Socials + Newsletter
  .more{
    @include flex(flex-end);
    gap: 5em;
    padding: 5em 0 0;

    @include responsive($tabletSmall, max){
      align-items: flex-start;
      flex-direction: column;
    }
  }

  // Secondary nav
  .secondary {
    border-top: 1px solid rgba($pierre-blanche, 0.1);
    padding: 2.5em 0;
    margin-top: 2.5em;

    ul{
      @include flex(center);
      flex-wrap: wrap;

      @include responsive($tabletSmall){
        justify-content: flex-end;
      }

      li{
        &:not(:last-child){
          a:after{
            content: '·';
            display: inline-block;
            padding: 0 0.57em;
          }
        }
        
        a {
          font: 500 14px $main-font;
          @include font-limited(14);
          color: $pierre-blanche;
          filter: opacity(0.5);
        }
      }
    }
  }
}

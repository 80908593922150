$menuBreakpoint: $tablet;

.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 120;
  pointer-events: none;

  body:not(.--animating) &{
    transition: background-color 500ms $ease,
                box-shadow 500ms $ease,
                transform 500ms $ease;
  }

  .container{
    padding-left: 1em;
    padding-right: 1em;

    @include responsive($menuBreakpoint){
      padding-left: 2.5em;
      padding-right: 2.5em;
    }
  }

  // When header is hiding
  &.--hide{
    transform: translate(0, -100%);
  }

  // When header is scrolling
  &.--scroll{
    &:not(.--hide) {
      transform: none;
      background: $ardoise-d2;

      .Brand .MainLogo{
        width: 80px;

        @include responsive($tabletSmall){
          width: get-vw(80);
        }

        @include responsive($deskXXL){
          width: 80px;
        }
      }
      
      .Header__main{
        padding-top: 0.5em;
        padding-bottom: 0.5em;
      }
    }
  }

  // When the service visual zoom popup is activated
  // body.--show-service-popup &{
  //   &.--hide,
  //   &.--scroll{
  //     transform: translate(0, -100%);
  //     box-shadow: 0px 0px 10px rgba($black, 0);
  //   }
  // }

  &__main{
    position: relative;
    padding-top: 1em;
    padding-bottom: 1em;
    z-index: 2;

    .container{
      @include flex(center, space-between);
      gap: 0.5em;

      > .Btn.--support{
        pointer-events: all;
        margin-top: 0;

        @include responsive($menuBreakpoint){
          display: none;
        }
      }
    }

    body:not(.--animating) &{
      transition: padding 500ms $ease;
    }
  }

  > .ov{
    @include cover(fixed);
    background: rgba($black, 0.8);
    z-index: 0;
    pointer-events: all;
    transition: opacity 500ms $ease;
    
    body:not(.--show-menu) &{
      opacity: 0;
      pointer-events: none;
    }
  }
}

.TimelineSection{
  padding: 2em 0;

  &:last-child{
    .title{
      @include font-vw(200);
    }
  }

  .title{
    @include flex(center, center);
    flex-wrap: nowrap;
    white-space: nowrap;

    font: 700 5vw $fraunces;
    @include font-vw(310);
    line-height: 1;
    color: #F5F7F4;
    margin-bottom: 0.5em;

    > span:nth-child(2) {
      display: inline-flex;
      align-items: center;

      &:before{
        content: '';
        display: inline-block;
        margin: 0.1em 0.15em 0;
        height: 0.3em;
        width: 0.3em;
        background-image: url("data:image/svg+xml,%3Csvg width='86' height='51' viewBox='0 0 86 51' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M57.23 49.6802C52.4767 49.6802 48.1367 48.6469 44.21 46.5802C40.2833 44.5135 36.615 42.1369 33.205 39.4502C29.795 36.7635 26.5917 34.3869 23.595 32.3202C20.5983 30.2535 17.705 29.2202 14.915 29.2202C11.0917 29.2202 8.25 30.7702 6.39 33.8702C4.53 36.9702 3.49666 41.8269 3.29 48.4402C3.29 49.6802 2.825 50.3002 1.895 50.3002C0.965 50.3002 0.5 49.6802 0.5 48.4402C0.5 33.1469 2.515 21.4702 6.545 13.4102C10.6783 5.3502 17.9633 1.32021 28.4 1.32021C33.1533 1.32021 37.4933 2.35354 41.42 4.4202C45.3467 6.48687 49.015 8.86354 52.425 11.5502C55.835 14.2369 59.0383 16.6135 62.035 18.6802C65.0317 20.7469 67.925 21.7802 70.715 21.7802C74.6417 21.7802 77.535 20.2302 79.395 17.1302C81.255 14.0302 82.2367 9.17355 82.34 2.56021C82.34 1.3202 82.805 0.700195 83.735 0.700195C84.665 0.700195 85.13 1.3202 85.13 2.56021C85.13 17.8535 83.0633 29.5302 78.93 37.5902C74.9 45.6502 67.6667 49.6802 57.23 49.6802Z' fill='%23F5F7F4'/%3E%3C/svg%3E%0A");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }

  .events{
    @include flex();
    flex-direction: column;
    gap: 3.5em;
    
    @include responsive($tabletSmall){
      gap: 10em;
    }
  }
}

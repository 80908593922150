.NavDropdown{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0; left: 0;
  z-index: 1;
  overflow-y: scroll;
  transition: transform 500ms $ease,
              opacity 500ms $ease;

  @include responsive($menuBreakpoint, max){
    padding: 0.5em;
  }
  
  @include responsive($menuBreakpoint){
    padding: 0.25em;
  }

  body:not(.--show-menu) &{
    opacity: 0;
    transform: translate(0, -25%) scale(0.9);

    .container{
      pointer-events: none;
    }
  }

  body.--show-menu &{
    pointer-events: all;
  }
  
  > .wrapper{
    background-color: $white;
    padding: 10em 0 2.5em;

    @include responsive($menuBreakpoint, max){
      padding: 7em 0 2.5em;
    }
  }

  .container {
    pointer-events: all;
  }

  .container > .main{
    @include flex(flex-start, space-between);
    flex-wrap: wrap;
    width: 100%;

    @include responsive($menuBreakpoint, max){
      gap: 2em;
    }

    @include responsive($mobile, max){
      flex-direction: column;
    }

    > *{
      @include responsiveMinMax($mobile, $menuBreakpoint){
        flex: 1 0 calc(50% - 2em);
        max-width: calc(50% - 2em);
      }
    }
  }

  .container > .secondary{
    @include flex(flex-end, space-between);
    gap: 2.5em;
    margin-top: 2.5em;

    @include responsive($menuBreakpoint, max){
      align-items: flex-start;
      flex-direction: column;
    }
  }
}

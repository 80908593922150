.NavLanguages{
  pointer-events: all;
  margin-left: 0.5em;

  ul {
    @include flex(center);
    color: $white;
    transition: color 500ms $ease;

    body.--show-menu &{
      color: $ardoise-d2;
    }

    body:not(.--show-menu) .Header.--dark:not(.--scroll) &{
      color:  $ardoise;

      li:not(:last-child):after{
        background-color: rgba($ardoise, 0.4);
      } 
    }

    li{
      @include flex(center);
      font-size: 12px;
      @include font-limited(15);
      font-weight: 700;

      &:not(:last-child){
        &:after{
          @include content();
          height: 1.2em;
          width: 2px;
          background-color: rgba($white, 0.4);
          margin: 0 0.5em;
          transition: background-color 500ms $ease;

          body.--show-menu &{
            background-color: rgba($ardoise-d2, 0.4);
          }
        }
      }

      a:not(.active){
        opacity: 0.4;
      }
    }
  }
}

.ContactInfos{
  grid-gap: 2em;
  color: $ardoise;
  padding: 2.5em 1.5em;
  width: 100%;

  @include responsive($tabletSmall){
    max-width: get-vw(510);
    padding: 3.5em;
  }

  @include responsive($deskXXL){
    max-width: 510px;
  }

  &, > div{
    display: grid;
  }

  > div{
    > span{
      display: block;
      font-weight: 700;
    }

    > a{
      display: inline-block;
    }
  }
}

.AllEvents{
  padding-top: 0;
  margin-top: -2em;

  .form-container{
    background-color: $pierre-blanche;
    border-radius: 0 0 30px 30px;
    padding-top: 4.5em;
    padding-bottom: 2.5em;
    margin-bottom: 5em;

    .container.--form{
      @include responsive($tabletSmall, max){
        padding-right: 0;
      }

      form{
        @include flex(flex-start);
        flex-wrap: wrap;
        gap: 2em;

        @include responsive($tabletSmall, max){
          flex-direction: column;
          overflow: hidden;
        }
      
        @include responsive($tabletSmall){
          gap: 5em;
        }
  
        .Checkboxes.--categories{
          @include flex(flex-start);
          flex-wrap: wrap;
          gap: 0.5em;
          padding-top: 0.4em;
          flex: 1;

          @include responsive($tabletSmall, max){
            flex-wrap: nowrap;
            white-space: nowrap;
            overflow-x: scroll;
            width: 100%;
            padding-right: 1.5em;
          }
        }
  
        .Form__group.--select{
          width: 100%;
          max-width: 250px;
          
          @include responsiveMinMax($tabletSmall, $deskXXL){
            max-width: get-vw(250);
          }
  
          @include responsive($tabletSmall){
            margin-left: auto;
          }
        }
      }
    }
  }

  .container.--events{
    .grid{
      display: grid;
      grid-gap: 5em;
    }

    .month{
      > time{
        position: relative;
        display: block;
        margin-bottom: 1.25em;

        &:before{
          @include content();
          position: absolute;
          top: 50%; left: 0;
          transform: translate(0, -50%);
          height: 1px;
          width: 100%;
          background-color: rgba($bleu-marine-l3, 0.85);
        }

        > div{
          font: 700 20px $main-font;
          @include font-limited(20);
          text-transform: uppercase;
          color: $bleu-marine;
          background-color: $white;
          padding-right: 0.5em;
          position: relative;
          display: inline-block;

          i{
            font: italic 700 0.85em $fraunces;
            color: $bleu-marine-l1;
            padding-left: 0.2em;
          }
        }
      }

      > .related_events{
        display: grid;
        grid-gap: 1em;
      
        @include responsive($tabletSmall){
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }
}
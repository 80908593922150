.FeaturedEvents{
  margin-left: auto;
  overflow: hidden;
  // max-width: 272px;
  
  @include responsive($tabletSmall, max) {
    background-color: $pierre-blanche;
    padding: 3em 1.5em 0;
  }
  
  @include responsive($tabletSmall){
    position: absolute;
    bottom: -5%; right: 5%;
    z-index: 2;

    max-width: get-vw(298);
  }
  
  @include responsive($deskXXL){
    max-width: 290px;
    right: 15%;
  }

  h2{
    font-size: 20px;
    @include font-limited(20);
    margin-bottom: 1em;
    
    @include responsive($tabletSmall){
      color: $white;
    }

    i{
      font-size: 0.65em;
    }
  }

  .swiper{
    .swiper-slide{
      opacity: 0;
      transform: scale(0.5);
      transition: opacity 500ms $ease,
                  transform 500ms $ease;

      &.swiper-slide-active{
        transform: scale(1);
        opacity: 1;
      }
    }

    .swiper-navigation{
      position: absolute;
      bottom: 100%; right: 0;
      transform: translate(0, -50%);

      ul{
        gap: 0.5em;

        li {
          button{
            .CircleArrows{
              height: 30px;
              width: 30px;

              @include responsive($tabletSmall, max){
                border-color: $ardoise;
              }

              @include responsiveMinMax($tabletSmall, $deskXXL){
                height: get-vw(34);
                width: get-vw(34);
              }
            }

            .Icon.--arrow svg > *{
              @include responsive($tabletSmall, max){
                stroke: $ardoise;
              }
            }
          }
        }
      }
    }
  }
}